import { useState } from 'react';
import TaskDeadlineEntryIcon from 'web/components/assignmentTasks/TaskDeadlineEntryIcon';
import EditAssignmentEntryDialog from 'web/screens/dialogs/assignmentTaskEntry/EditAssignmentEntryDialog';

export default function TaskDeadlineEntry({ deadlineEntry }) {
	const [showEntryDialog, setShowEntryDialog] = useState(false);

	return (
		<>
			<TaskDeadlineEntryIcon
				deadlineEntry={deadlineEntry}
				onClick={() => setShowEntryDialog(true)}
			/>

			<EditAssignmentEntryDialog
				deadlineEntry={deadlineEntry}
				open={showEntryDialog}
				onClose={() => setShowEntryDialog(false)}
			/>
		</>
	);
}
