import styled from 'styled-components';
import { Cell } from '../../Layout';

import TaskDeadlineEntry from './TaskDeadlineEntry';

import {
	currentYear,
	currentMonth,
} from 'web/components/assignmentTasksCalendarTimeline/utils';

export default function TaskMonthlyDeadlines({
	deadlineEntries,
	year,
	month,
	monthIndex,
}) {
	const currentDeadlineEntries =
		deadlineEntries?.filter(deadlineEntry => {
			const deadlineEntryDate = new Date(deadlineEntry.date);
			const deadlineEntryYear = deadlineEntryDate.getFullYear();
			const deadlineEntryMonth = deadlineEntryDate.getMonth();

			return (
				year === deadlineEntryYear && monthIndex === deadlineEntryMonth
			);
		}) ?? [];

	return (
		<TaskTimelineCell
			key={`${year}-${month.short}`}
			isCurrentMonth={year === currentYear && monthIndex === currentMonth}
		>
			{currentDeadlineEntries.map(deadlineEntry => (
				<TaskDeadlineEntry
					key={deadlineEntry._id}
					deadlineEntry={deadlineEntry}
				/>
			))}
		</TaskTimelineCell>
	);
}

const TimelineCellWrapper = styled(Cell)`
	justify-content: center;
`;

function TaskTimelineCell({ isCurrentMonth, children }) {
	return (
		<TimelineCellWrapper $current={isCurrentMonth}>
			{children}
		</TimelineCellWrapper>
	);
}
