import styled from 'styled-components';
import useLocalStorage from 'hooks/useLocalStorageSetting';

import { StyledLink } from 'web/components/Link';
import TaskCalendarButton from 'web/components/TaskCalendarButton';

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;
`;

export default function CompanyName({ id, company }) {
	const { setValue: setTenant } = useLocalStorage('tenant');

	return (
		<Wrapper>
			<TaskCalendarButton companyId={id} />{' '}
			<StyledLink
				to={`/bedrifter/${id}/`}
				onClick={() => setTenant(company)}
			>
				{company.name}
			</StyledLink>
		</Wrapper>
	);
}
