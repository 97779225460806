import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import Tooltip from '@mui/material/Tooltip';

import { TaskDeadlineEntryStatusPill } from 'components/Pill';

import TaskDeadlineEntryTooltip from 'web/components/assignmentTasks/TaskDeadlineEntryTooltip';

import { GET_TASK_DEADLINE_ENTRY_BY_TYPE } from 'api/queries/companyAssignmentTaskQueries';

const Wrapper = styled.div`
	button {
		max-width: 140px;
		width: 100%;
		display: flex;
		justify-content: center;
	}

	${({ $showDeadline }) =>
		$showDeadline &&
		`
		display: grid;
		grid-template-columns: 140px 80px;
		align-items: center;
		gap: .5rem;
	`}
`;
const StatusIcon = styled.div`
	font-size: 20px;
	line-height: 1;
	position: absolute;
	top: -7px;
	right: -7px;
`;

export default function TaskDeadlineEntryByType({
	companyId,
	type,
	year,
	showDeadline = false,
	setEditAssignmentEntry,
}) {
	const {
		data: { deadlineEntry } = { deadlineEntry: null },
		loading,
		error,
	} = useQuery(GET_TASK_DEADLINE_ENTRY_BY_TYPE, {
		variables: {
			companyId,
			type,
			year,
		},
	});

	if (error) return <div>Feil ved lasting</div>;

	if (loading || !deadlineEntry) {
		return <div />;
	}

	return (
		<div>
			<Wrapper $showDeadline={showDeadline}>
				<TaskDeadlineEntryStatusPill
					$statusCode={deadlineEntry.status}
					onClick={() => setEditAssignmentEntry(deadlineEntry)}
				>
					<TaskDeadlineEntryTooltip
						comments={deadlineEntry?.comments}
						files={deadlineEntry?.files}
					>
						<div>{deadlineEntry?.status ?? 'Ikke startet'}</div>

						{deadlineEntry.postponedStatus && (
							<StatusIcon title="Frist søkt utsatt">
								<AccessTimeFilledIcon fontSize="inherit" />
							</StatusIcon>
						)}
					</TaskDeadlineEntryTooltip>
				</TaskDeadlineEntryStatusPill>

				{showDeadline && <DeadlineDate date={deadlineEntry?.date} />}
			</Wrapper>
		</div>
	);
}

const TooltipTextWrapper = styled.div`
	font-size: 0.8rem;
`;

const DeadlineText = styled.div`
	display: inline-flex;
	align-items: center;
	gap: 0.25rem;
	opacity: 0.75;
	font-size: 12px;
`;

function DeadlineDate({ date }) {
	if (!date) {
		return null;
	}

	return (
		<div>
			<Tooltip
				title={<TooltipText date={date} />}
				placement="right"
				arrow
			>
				<DeadlineText>
					<CalendarIcon sx={{ fontSize: 12 }} />
					<span>
						{new Date(date).toLocaleDateString('no-NO', {
							day: '2-digit',
							month: 'short',
						})}
					</span>
				</DeadlineText>
			</Tooltip>
		</div>
	);
}

function TooltipText({ date }) {
	return (
		<TooltipTextWrapper>
			Frist{' '}
			{new Date(date).toLocaleDateString('no-NO', {
				day: '2-digit',
				month: 'short',
				year: 'numeric',
			})}
		</TooltipTextWrapper>
	);
}
