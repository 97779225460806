import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';

import useSortOrder from 'hooks/useSortOrder';

import { DocumentParagraph } from 'components/DocumentLayout';
import { InlineLoading } from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';

import DesktopLayout, { Content, NarrowContent } from 'web/components/Layout';
import Document from 'web/components/views/document/Document';
import FileBrowser from 'web/components/views/FileBrowser';

import { GET_COMPANY_DOCUMENTS } from 'api/queries/documentQueries';

export default function Documents({ companyId }) {
	let { documentNodeId } = useParams();

	const [includeHidden, setIncludeHidden] = useState(false);
	const [sort, setNewOrder] = useSortOrder('name');

	const parentId = documentNodeId || 'root';

	const {
		data: {
			documentNode = null,
			parentDocumentNodes = [],
			documentNodes = null,
		} = {},
		loading,
		error,
	} = useQuery(GET_COMPANY_DOCUMENTS, {
		fetchPolicy: 'network-only',
		variables: {
			tenantId: companyId,
			parentId,
			order: sort.order,
			orderBy: sort.orderBy,
			includeHidden,
		},
	});

	const parentList = parentDocumentNodes.map(parent => ({
		to: '/dokumenter/' + parent._id + '/',
		label: parent.name,
	}));

	let breadcrumbs = [
		{
			to: '/dokumenter',
			label: 'Dokumenter',
		},
		...parentList,
	];

	if (documentNode?.name) {
		breadcrumbs.push({ label: documentNode.name });
	}

	return (
		<DesktopLayout loading={loading} breadcrumbs={breadcrumbs}>
			<DocumentsFileBrowser
				documentNode={documentNode}
				documentNodes={documentNodes}
				parentId={parentId}
				parentName={documentNode?.name}
				companyId={companyId}
				sort={sort}
				setNewOrder={setNewOrder}
				includeHidden={includeHidden}
				setIncludeHidden={setIncludeHidden}
				loading={loading}
				error={error}
			/>
		</DesktopLayout>
	);
}

function DocumentsFileBrowser({
	documentNode,
	documentNodes,
	parentId,
	parentName,
	companyId,
	sort,
	setNewOrder,
	includeHidden,
	setIncludeHidden,
	loading = false,
	error = null,
}) {
	if (loading) {
		return (
			<Content>
				<InlineLoading />
			</Content>
		);
	}

	if (error) {
		return (
			<Content>
				<ErrorMessage errors={error} />
			</Content>
		);
	}

	if (!loading && !documentNode && documentNodes?.items?.length === 0) {
		return (
			<Content>
				<DocumentParagraph $align="center">
					Beklager, men du har ikke tilgang til denne fila.
				</DocumentParagraph>
			</Content>
		);
	}

	if (documentNode?.type === 'file') {
		return (
			<NarrowContent>
				<Document documentNode={documentNode} />
			</NarrowContent>
		);
	}

	return (
		<Content>
			<FileBrowser
				documents={documentNodes}
				parentId={parentId}
				parentName={parentName}
				tenantId={companyId}
				linkBase="/dokumenter/"
				sort={sort}
				setNewOrder={setNewOrder}
				includeHidden={includeHidden}
				setIncludeHidden={setIncludeHidden}
			/>
		</Content>
	);
}
