import Button from '@mui/material/Button';

import { useSnackbar } from 'web/contexts/SnackbarContext';
import Icon from 'components/Icon';

import { download } from 'utils/fileHandling';
import SplitButton from 'web/components/SplitButton';

const signedOption = 'Last ned signert dokument';

export default function DownloadDocument({
	getDocument,
	getSignedDocument,
	signedPDFGenerated,
}) {
	const { notify } = useSnackbar();

	const onDownload = async documentQuery => {
		try {
			const response = await documentQuery();

			download(response.data.item.url, response.data.item.name);
		} catch (error) {
			console.error(error);
			notify('Noe gikk galt under nedlasting av dokument', 'error');
		}
	};

	const handleClick = option => {
		if (option === signedOption) {
			onDownload(getSignedDocument);
		} else {
			onDownload(getDocument);
		}
	};

	const getOptions = () => {
		let options = ['Last ned dokument'];
		if (signedPDFGenerated) {
			options.unshift(signedOption);
		}
		return options;
	};

	const options = getOptions();

	if (options.length === 1) {
		return (
			<DownloadButton
				label="Last ned dokument"
				onDownload={() => onDownload(getDocument)}
			/>
		);
	}

	return (
		<SplitButton
			options={options}
			onClick={handleClick}
			buttonGroupProps={{
				'aria-label': 'Nedlastinger',
			}}
			buttonProps={{
				startIcon: <Icon icon="file-download" />,
			}}
			splitButtonProps={{
				'aria-label': 'Velg dokument for nedlasting',
			}}
			splitMenuId="download-menu"
		/>
	);
}

const DownloadButton = ({ label, onDownload }) => {
	return (
		<Button
			onClick={onDownload}
			variant="outlined"
			startIcon={<Icon icon="file-download" />}
		>
			{label}
		</Button>
	);
};
