import { useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { InlineLoading } from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import AssignmentTasksCalendarTimeline from './AssignmentTasksCalendarTimeline';

import CalendarTimelineProvider from './context/CalendarTimelineContext';

import { GET_TASKS } from 'api/queries/companyAssignmentTaskQueries';

export default function CompanyTasks({ companyId }) {
	const [showOnlyOverdue, setShowOnlyOverdue] = useState(false);

	const {
		data: { tasks } = { tasks: [] },
		loading,
		error,
	} = useQuery(GET_TASKS, {
		variables: {
			companyId,
			onlyActive: true,
			onlyOverdue: showOnlyOverdue,
		},
	});

	// Group all tasks by category
	const tasksGroupedByCategory = useMemo(
		() =>
			tasks
				.reduce((categories, task) => {
					let categoryGroup = categories.find(
						group => group.category.name === task.category.name
					);

					if (!categoryGroup) {
						categoryGroup = {
							category: task.category,
							tasks: [],
						};

						categories.push(categoryGroup);
					}

					categoryGroup.tasks.push(task);

					return categories;
				}, [])
				.sort((a, b) => a.category.order - b.category.order),
		[tasks]
	);

	if (loading) return <InlineLoading />;

	if (error) return <ErrorMessage errors={[error]} />;

	return (
		<CalendarTimelineProvider
			companyId={companyId}
			tasksGroupedByCategory={tasksGroupedByCategory}
			showOnlyOverdue={showOnlyOverdue}
			setShowOnlyOverdue={setShowOnlyOverdue}
		>
			<AssignmentTasksCalendarTimeline />
		</CalendarTimelineProvider>
	);
}
