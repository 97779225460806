import styled, { css } from 'styled-components';

import {
	scoreColor,
	companyControlStatusColor,
	assigmentTaskDeadlineEntryStatusColor,
	companyContractStatusColor,
} from 'utils/colors';

const sharedStyle = css`
	color: ${p => p.theme.palette.text.primary};
	border-radius: 100px;
	font-size: 12px;
	line-height: 1.5;
	padding: 3px 16px;
`;

export const RiskScorePill = styled.div`
	${sharedStyle}

	background: ${p => scoreColor(p.$score)};
	display: inline-block;
	text-align: center;
	padding: 3px;
	width: 80px;
`;

export const CompanyControlStatusPill = styled.div`
	${sharedStyle}

	background: ${p => companyControlStatusColor(p.$statusCode)};
	display: inline-block;
`;

export const AnnualSettlementControlStatusPill = styled.div`
	${sharedStyle}

	background: ${p => companyControlStatusColor(p.$statusCode)};
	display: inline-block;
`;

export const TaskDeadlineEntryStatusPill = styled.button`
	${sharedStyle}

	background: ${p =>
		assigmentTaskDeadlineEntryStatusColor({ status: p.$statusCode })};
	border: none;
	cursor: ${p => (p.onClick ? 'pointer' : 'default')};
	display: flex;
	align-items: center;
	gap: 10px;
	position: relative;
`;

export const CompanyContractsStatusPill = styled.div`
	${sharedStyle}

	background: ${p => companyContractStatusColor(p.$statusCode)};
	display: inline-block;
	margin-top: 5px;
	min-width: 110px;
`;
