const now = new Date();

const currentYear = now.getFullYear();
const currentMonth = now.getMonth();

const monthsInYear = 12;
const months = Array.from({ length: monthsInYear }, (_, i) => {
	const date = new Date(2000, i); // Year 2000 is arbitrary, only the month matters

	return {
		long: date.toLocaleString('no', { month: 'long' }),
		short: date.toLocaleString('no', { month: 'short' }),
	};
});

export { currentYear, currentMonth, monthsInYear, months };
