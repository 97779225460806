import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import Stack from '@mui/material/Stack';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorAlert from 'components/ErrorAlert';
import {
	Dialog,
	DialogTitle,
	DialogCloseButton,
	DialogActions,
	DialogContent,
	Button,
} from 'components/dialogs/DialogBox';

import Form, { AutoComplete, FormGroup, TextField } from 'web/form/Form';
import AddUserTypeToggle, {
	types,
} from 'web/components/inputs/AddUserTypeToggle';
import AutocompleteOptionWithTooltip from 'web/components/inputs/AutocompleteOptionWithTooltip';
import { UserCreateDialogForm } from 'web/screens/dialogs/users/UsersCreateDialog';

import { PAGINATE_USERS } from 'api/queries/userQueries';
import { ADD_OWNER_TO_COMPANY } from 'api/mutations/tenantMutations';

export default function AddOwnerDialog({
	companyId,
	open,
	onClose,
	onlyCreatUser = false,
	user,
}) {
	const { notify } = useSnackbar();

	const defaultType = onlyCreatUser ? types.NEW : types.EXISTING;

	const [type, setType] = useState(defaultType);
	const handleTypeChange = (event, newType) => {
		if (newType !== null) {
			setType(newType);
		}
	};

	const [addOwner, { loading: addOwnerLoading, error: addOwnerError }] =
		useMutation(ADD_OWNER_TO_COMPANY, {
			refetchQueries: ['findOneTenant'],
		});

	const handleAddOwner = async ({ userId, note }) => {
		try {
			if (!userId) {
				return;
			}

			const { data } = await addOwner({
				variables: {
					_id: companyId,
					userId,
					note,
				},
			});

			if (data && data.added) {
				notify('Rettighetshaver ble lagt til');

				onClose();
			}
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="sm"
			aria-labelledby="dialog-title"
			aria-describedby="dialog-description"
		>
			<DialogTitle id="dialog-title">
				Legg til reell rettighetshaver
			</DialogTitle>

			<DialogCloseButton onClick={onClose} />

			{!onlyCreatUser && !user && (
				<AddUserTypeToggle
					type={type}
					handleTypeChange={handleTypeChange}
				/>
			)}

			{addOwnerError && (
				<DialogContent sx={{ paddingBottom: 0 }}>
					<ErrorAlert error={addOwnerError} />
				</DialogContent>
			)}

			{type === types.EXISTING && (
				<AddOwnerForm
					onClose={onClose}
					handleAddOwner={handleAddOwner}
					addOwnerLoading={addOwnerLoading}
					user={user}
				/>
			)}

			{type === types.NEW && (
				<AddNewUserForm
					onClose={onClose}
					handleAddOwner={handleAddOwner}
					addOwnerLoading={addOwnerLoading}
					user={user}
				/>
			)}
		</Dialog>
	);
}

function AddOwnerForm({ user, onClose, handleAddOwner, addOwnerLoading }) {
	const {
		data: { users } = { users: { count: 0, items: [] } },
		loading: userLoading,
		error: userError,
	} = useQuery(PAGINATE_USERS, {
		variables: { orderBy: 'name', order: 1 },
	});

	const defaultValues = user
		? {
				userId: user.userId,
				note: null,
			}
		: {
				userId: null,
				note: null,
			};

	return (
		<Form
			formProps={{ loading: userLoading || addOwnerLoading }}
			onSubmit={handleAddOwner}
			values={defaultValues}
		>
			<DialogContent>
				<ErrorAlert error={userError} />

				<FormGroup>
					<AutoComplete
						name="userId"
						label="Reell rettighetshaver"
						defaultValue={defaultValues.userId}
						getOptionKey={option => option.value}
						options={users.items.map(user => ({
							value: user._id,
							label: user.name,
							subdued:
								user.role === undefined ||
								user.role === null ||
								user.role == 'silent',
							tooltip: [
								<Stack
									key="1"
									direction="row"
									alignItems="center"
									gap={1}
								>
									<MailIcon />
									{user.email || 'Mangler e-post'}
								</Stack>,
								<Stack
									key="2"
									direction="row"
									alignItems="center"
									gap={1}
								>
									<PhoneIcon />
									{user.phone || 'Mangler telefon'}
								</Stack>,
							],
						}))}
						renderOption={(props, option) => (
							<AutocompleteOptionWithTooltip
								{...props}
								key={`userId-${option.value}`}
								option={option}
							/>
						)}
						required
					/>
				</FormGroup>

				<FormGroup>
					<TextField
						sx={{ width: '100%' }}
						name="note"
						label="Notat"
						maxRows="5"
						multiline={true}
					/>
				</FormGroup>
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>Avbryt</Button>

				<Button
					type="submit"
					variant="contained"
					disabled={userLoading || addOwnerLoading}
				>
					Legg til
				</Button>
			</DialogActions>
		</Form>
	);
}

function AddNewUserForm({ onClose, handleAddOwner, user }) {
	const onSubmitSuccess = ({ _id }, { note }) => {
		setTimeout(async () => {
			handleAddOwner({ userId: _id, note });
		}, 300); // Add a delay to allow the user to be created and the refetchQueries to run
	};

	return (
		<UserCreateDialogForm
			onClose={onClose}
			onSubmitSuccess={onSubmitSuccess}
			user={user}
			extraFormFields={<NoteField />}
		/>
	);
}

function NoteField() {
	return (
		<FormGroup>
			<TextField
				sx={{ width: '100%' }}
				name="note"
				label="Notat"
				maxRows="5"
				multiline={true}
			/>
		</FormGroup>
	);
}
