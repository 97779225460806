import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { companyRoles } from 'server/libs/companyRoles';

export default function CompanyRolesAutocomplete({
	onChange,
	hasError = false,
	defaultValue = [],
}) {
	return (
		<Autocomplete
			options={companyRoles}
			onChange={(_, value) => onChange(value)}
			defaultValue={defaultValue}
			renderInput={params => (
				<TextField
					id="company-roles-autocomplete"
					label="Bedriftsroller *"
					error={hasError}
					helperText={hasError ? 'Velg minst én rolle' : ''}
					{...params}
				/>
			)}
			noOptionsText="Ingen alternativer"
			multiple={true}
		/>
	);
}
