import { gql } from '@apollo/client';

export const COMPANY_HAS_TASKS = gql`
	query companyHasTasks($companyId: ID!) {
		companyHasTasks(companyId: $companyId)
	}
`;

export const GET_TASKS = gql`
	query getTasks(
		$companyId: ID!
		$onlyActive: Boolean
		$onlyOverdue: Boolean
	) {
		tasks: getTasks(
			companyId: $companyId
			onlyActive: $onlyActive
			onlyOverdue: $onlyOverdue
		) {
			_id
			name
			startDate
			category {
				name
				order
			}
			active
			notifications
			custom
			frequency
			frequencyLabel
			globalRoutine
			routine
			deadline {
				day
				weekday
				weekdayLabel
				month
				monthLabel
				relativeMonth
				relativeMonthLabel
				dates {
					day
					month
				}
			}
			subTasks {
				name
			}
		}
	}
`;

export const GET_DEFAULT_TASKS = gql`
	query getDefaultTasks {
		tasks: getDefaultTasks(types: ["GENERAL"]) {
			name
			category {
				name
				order
			}
		}
	}
`;

export const GET_COMPANY_TABLE_TASKS = gql`
	query getCompanyTableTasks {
		tasks: getCompanyTableTasks(types: ["GENERAL"]) {
			name
		}
	}
`;

export const FIND_ONE_COMPANY_ASSIGNMENT_TASK = gql`
	query findOneCompanyAssignmentTask($_id: ID!) {
		task: findOneCompanyAssignmentTask(_id: $_id) {
			_id
			name
			category {
				name
				order
			}
			frequencyLabel
			globalRoutine
			routine
		}
	}
`;

export const GET_TASK_DEADLINE_ENTRIES = gql`
	query getTaskDeadlineEntries($taskId: ID!, $isOverdue: Boolean) {
		deadlineEntries: getTaskDeadlineEntries(
			taskId: $taskId
			isOverdue: $isOverdue
		) {
			_id
			taskId
			type
			date
			notifications
			isOverdue
			status
			postponedStatus {
				status
				exported
			}
			comments {
				_id
			}
			files {
				_id
			}
			subTasks {
				name
				completed
				updatedAt
				updatedBy
				updatedByDisplayName
			}
			hasCompletedSubTasks
			history {
				_id
				event
				createdAt
				userId
				userDisplayName
			}
		}
	}
`;

export const GET_TASK_DEADLINE_ENTRY_IN_PERIOD = gql`
	query getTaskDeadlineEntryInPeriod(
		$companyId: ID!
		$taskName: String!
		$month: Int!
		$year: Int!
	) {
		deadlineEntry: getTaskDeadlineEntryInPeriod(
			companyId: $companyId
			taskName: $taskName
			month: $month
			year: $year
		) {
			_id
			taskId
			type
			date
			notifications
			isOverdue
			status
			postponedStatus {
				status
				exported
			}
			comments {
				_id
			}
			files {
				_id
			}
			subTasks {
				name
				completed
				updatedAt
				updatedBy
				updatedByDisplayName
			}
			hasCompletedSubTasks
			history {
				_id
				event
				createdAt
				userId
				userDisplayName
			}
		}
	}
`;

export const GET_TASK_DEADLINE_ENTRIES_IN_YEAR = gql`
	query getTaskDeadlineEntriesInYear(
		$companyId: ID!
		$taskName: String!
		$year: Int!
	) {
		deadlineEntries: getTaskDeadlineEntriesInYear(
			companyId: $companyId
			taskName: $taskName
			year: $year
		) {
			_id
			taskId
			type
			date
			notifications
			isOverdue
			status
			postponedStatus {
				status
				exported
			}
			comments {
				_id
			}
			files {
				_id
			}
			subTasks {
				name
				completed
				updatedAt
				updatedBy
				updatedByDisplayName
			}
			hasCompletedSubTasks
			history {
				_id
				event
				createdAt
				userId
				userDisplayName
			}
		}
	}
`;

export const GET_TASK_DEADLINE_ENTRY_BY_TYPE = gql`
	query getTaskDeadlineEntryByType(
		$companyId: ID!
		$type: AssignmentType!
		$year: Int!
	) {
		deadlineEntry: getTaskDeadlineEntryByType(
			companyId: $companyId
			type: $type
			year: $year
		) {
			_id
			taskId
			type
			date
			notifications
			isOverdue
			status
			postponedStatus {
				status
				exported
			}
			comments {
				_id
			}
			files {
				_id
			}
			subTasks {
				name
				completed
				updatedAt
				updatedBy
				updatedByDisplayName
			}
			hasCompletedSubTasks
			history {
				_id
				event
				createdAt
				userId
				userDisplayName
			}
		}
	}
`;

export const GET_DEADLINE_ENTRIES_OVERDUE_STATUS_FOR_COMPANY = gql`
	query getDeadlineEntriesOverdueStatusForCompany($companyId: ID!) {
		isOverdue: getDeadlineEntriesOverdueStatusForCompany(
			companyId: $companyId
		)
	}
`;

export const GET_COMPANIES_WITH_POSTPONED_TASK_DEADLINE_ENTRY = gql`
	query getCompaniesWithPostponedTaskDeadlineEntry(
		$year: Int!
		$departmentId: String
		$type: String!
		$postponedStatus: String!
	) {
		entries: getCompaniesWithPostponedTaskDeadlineEntry(
			year: $year
			departmentId: $departmentId
			type: $type
			postponedStatus: $postponedStatus
		) {
			company {
				_id
				name
				orgNo
				proffData
				owners: boardMembers(roles: ["innehaver"]) {
					_id
					name
					socialno
				}
			}
			task {
				_id
				name
			}
			deadlineEntry {
				_id
				date
				status
				postponedStatus {
					status
					exported
				}
			}
		}
	}
`;
