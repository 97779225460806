import Button from '@mui/material/Button';

import Icon from 'components/Icon';
import SplitButton from 'web/components/SplitButton';

import { useSnackbar } from 'web/contexts/SnackbarContext';

const signedOption = 'Se signert dokument';

export default function ViewPdf({
	getDocument,
	getSignedDocument,
	signedPDFGenerated,
}) {
	const { notify } = useSnackbar();

	const openDocument = async documentQuery => {
		try {
			const response = await documentQuery();

			const url = response.data.item.url;
			//Open url in new tab
			window.open(url, '_blank');
		} catch (error) {
			console.error(error);
			notify('Noe gikk galt og dokumentet kan ikke vises', 'error');
		}
	};

	const handleClick = option => {
		if (option === signedOption) {
			openDocument(getSignedDocument);
		} else {
			openDocument(getDocument);
		}
	};

	const getOptions = () => {
		let options = ['Se dokument'];
		if (signedPDFGenerated) {
			options.unshift(signedOption);
		}
		return options;
	};

	const options = getOptions();
	if (options.length === 1) {
		return (
			<Button
				onClick={handleClick}
				startIcon={<Icon icon="file-pdf" />}
				variant="outlined"
			>
				Se dokument
			</Button>
		);
	}

	if (options.length === 0) {
		return null;
	}

	return (
		<SplitButton
			options={options}
			onClick={handleClick}
			buttonGroupProps={{
				'aria-label': 'Se dokumenter',
			}}
			buttonProps={{
				startIcon: <Icon icon="file-pdf" />,
				variant: 'outlined',
			}}
			splitButtonProps={{
				'aria-label': 'Velg dokument for visning',
			}}
			splitMenuId="view-document-menu"
		/>
	);
}
