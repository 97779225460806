import { Link } from 'react-router';

import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';

import TimelineYearSelect from './TimelineYearSelect';

import { useCalendarTimeline } from '../context/CalendarTimelineContext';

export default function Toolbar() {
	const { companyId, showOnlyOverdue, setShowOnlyOverdue } =
		useCalendarTimeline();

	return (
		<>
			<TimelineYearSelect />

			<Button
				variant="outlined"
				size="small"
				onClick={() => setShowOnlyOverdue(prevState => !prevState)}
			>
				{showOnlyOverdue ? 'Vis alle' : 'Vis forfalte'}
			</Button>

			<Button
				variant="outlined"
				size="small"
				startIcon={<EditIcon />}
				component={Link}
				to={`/bedrifter/${companyId}/oppdrag/planlegg/`}
			>
				Rediger oppgaver
			</Button>
		</>
	);
}
