import {
	TimelineAssignmentSidebar,
	TaskRow,
	TaskCell,
	CategoryName,
	TaskName,
} from '../Layout';

import { useCalendarTimeline } from '../../context/CalendarTimelineContext';

export default function TimelineSidebar() {
	const { tasksGroupedByCategory } = useCalendarTimeline();

	return (
		<TimelineAssignmentSidebar>
			<TaskRow>
				{tasksGroupedByCategory.map(
					(categoryGroup, assignmentIndex) => (
						<Cell
							key={assignmentIndex}
							categoryGroup={categoryGroup}
						/>
					)
				)}
			</TaskRow>
		</TimelineAssignmentSidebar>
	);
}

function Cell({ categoryGroup }) {
	return (
		<>
			<TaskCell>
				<CategoryName>{categoryGroup.category.name}</CategoryName>
			</TaskCell>

			{categoryGroup.tasks.map((task, taskIndex) => (
				<TaskCell key={taskIndex}>
					<TaskName>{task.name}</TaskName>
				</TaskCell>
			))}
		</>
	);
}
