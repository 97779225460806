import { Fragment, useState } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';

import { getRoleLabel } from 'server/libs/companyRoles';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorMessage from 'components/ErrorMessage';
import { Card, CardContent } from 'components/GridCardLayout';

import { ADD_PROFF_DATA_DEVIATION_EXCEPTION } from 'api/mutations/tenantMutations';
import { FIND_USER_WITH_USER_ROLES } from 'api/queries/userQueries';
import { UPDATE_ONE_USER } from 'api/mutations/userMutations';

import {
	InfoSection,
	InfoTitle,
	InfoLineWrapper,
	InfoValue,
	IconButton,
} from 'web/components/panels/PanelItems';
import UpdateProffDataButton from './company/UpdateProffDataButton';
import UsersCreateDialog from 'web/screens/dialogs/users/UsersCreateDialog';
import AddOwnerDialog from './panels/dialogs/AddOwnerDialog';
import AddCompanyRoleDialog, {
	UserRolesField,
} from './panels/dialogs/AddCompanyRoleDialog';

import Icon from 'components/Icon';

const StyledList = styled(List)`
	padding: 0;
	margin: 0;

	a {
		color: inherit;
	}
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 16px;
	padding: 0 0 32px;

	@media (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: 1600px) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

const ErrorTextMessage = styled.span`
	color: ${p => p.theme.palette.status.error};
	font-weight: 700;
`;

const ToLower = styled.span`
	text-transform: lowercase;
`;

export default function ProffDataDeviations({ companyId, deviations }) {
	if (!deviations.hasDeviations) {
		return (
			<Card>
				<CardContent>
					<ListItemText>Bedriften har ingen avvik!</ListItemText>
				</CardContent>
			</Card>
		);
	}

	return (
		<>
			{deviations.missingProffData && (
				<ErrorMessage
					title="Mangler data fra Proff!"
					errors={
						'Data fra proff.no mangler! Klikk på knappen for å hente siste data fra proff.no.'
					}
				/>
			)}

			<Grid>
				{deviations.oldProffData && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>Utdatert data fra Proff</InfoTitle>

								<div>
									Data fra proff.no har ikke blitt på over 6
									måneder.
									<UpdateProffDataButton
										companyId={companyId}
										hasProffData={true}
									/>
								</div>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.companyName && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Navn er registrert i Proff med:
								</InfoTitle>

								<div>{deviations.companyName}</div>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.companyAuditorsNotInProff && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Revisorfirma ikke registert i Proff:
								</InfoTitle>
								<StyledList>
									{deviations.companyAuditorsNotInProff.map(
										auditor => (
											<Fragment
												key={`company-auditor-${auditor._id}`}
											>
												<ListItem>
													<CompanyAuditorsNotInProff
														companyId={companyId}
														auditor={auditor}
													/>
												</ListItem>
												<Divider component="li" />
											</Fragment>
										)
									)}
								</StyledList>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.proffUsersNotInCompany && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Personer registrert i Proff:
								</InfoTitle>

								<StyledList>
									{deviations.proffUsersNotInCompany.map(
										user => (
											<Fragment
												key={`proff-user-${user.personId}-${user.role}`}
											>
												<ListItem>
													<ProffUsersNotInCompany
														companyId={companyId}
														user={user}
													/>
												</ListItem>
												<Divider component="li" />
											</Fragment>
										)
									)}
								</StyledList>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.companyUsersNotInProff && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Personer ikke registrert i Proff:
								</InfoTitle>

								<StyledList>
									{deviations.companyUsersNotInProff.map(
										user => (
											<Fragment
												key={`company-user-${user._id}-${user.role}`}
											>
												<ListItem>
													<CompanyUsersNotInProff
														companyId={companyId}
														user={user}
													/>
												</ListItem>
												<Divider component="li" />
											</Fragment>
										)
									)}
								</StyledList>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.proffOwnersNotInCompany && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Reelle rettighetshavere registrert i Proff:
								</InfoTitle>

								<StyledList>
									{deviations.proffOwnersNotInCompany.map(
										user => (
											<Fragment
												key={`proff-owner-${user.name}`}
											>
												<ListItem>
													<ProffOwnersNotInCompany
														user={user}
														companyId={companyId}
													/>
												</ListItem>
												<Divider component="li" />
											</Fragment>
										)
									)}
								</StyledList>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.companyOwnersNotInProff && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Reelle rettighetshavere ikke registrert i
									Proff:
								</InfoTitle>

								<StyledList>
									{deviations.companyOwnersNotInProff.map(
										user => (
											<Fragment
												key={`company-owner-${user._id}`}
											>
												<ListItem>
													<CompanyOwnerNotInProff
														companyId={companyId}
														user={user}
													/>
												</ListItem>
												<Divider component="li" />
											</Fragment>
										)
									)}
								</StyledList>
							</CardContent>
						</Card>
					</InfoSection>
				)}
			</Grid>
		</>
	);
}

function CompanyAuditorsNotInProff({ companyId, auditor }) {
	return (
		<InfoLineWrapper>
			<InfoValue>
				<div>
					{auditor?.auditingCompany?.name ? (
						<span>
							<em>
								{auditor.name} ({auditor.auditingCompany.name})
							</em>{' '}
							skal ikke være revisor.
						</span>
					) : (
						<span>
							{auditor.name} mangler tilknyttet revisorfirma
						</span>
					)}
				</div>
			</InfoValue>

			<ArchiveButton
				companyId={companyId}
				exception={{
					type: 'COMPANY_AUDITOR_NOT_IN_PROFF',
					name: auditor.name,
					_id: auditor._id,
				}}
			/>
		</InfoLineWrapper>
	);
}

function ProffUsersNotInCompany({ companyId, user }) {
	return (
		<>
			<InfoLineWrapper>
				<InfoValue>
					<div>
						<em>{user.name}</em> skal være{' '}
						<ToLower>{user.title}</ToLower>.
					</div>
				</InfoValue>

				{user.isRegistered ? (
					<AddRoleToUserButton companyId={companyId} user={user} />
				) : (
					<CreateUserWithRoleButton
						companyId={companyId}
						user={user}
					/>
				)}

				<ArchiveButton
					companyId={companyId}
					exception={{
						type: 'PROFF_USER_NOT_IN_COMPANY',
						name: user.name,
						role: user.role,
					}}
				/>
			</InfoLineWrapper>
		</>
	);
}

function CompanyUsersNotInProff({ companyId, user }) {
	return (
		<InfoLineWrapper>
			<InfoValue>
				<div>
					<em>{user.name}</em> skal ikke være{' '}
					<ToLower>{getRoleLabel(user.role)}</ToLower>.
				</div>
			</InfoValue>

			<ArchiveButton
				companyId={companyId}
				exception={{
					type: 'COMPANY_USER_NOT_IN_PROFF',
					name: user.name,
					role: user.role,
				}}
			/>
		</InfoLineWrapper>
	);
}

function ProffOwnersNotInCompany({ companyId, user }) {
	return (
		<InfoLineWrapper>
			<InfoValue>
				<div>
					<em>{user.name}</em> skal være rettighetshaver.
				</div>
			</InfoValue>

			{user.isRegistered ? (
				<AddUserAsOwnerButton companyId={companyId} user={user} />
			) : (
				<CreateUserAsOwnerButton companyId={companyId} user={user} />
			)}

			<ArchiveButton
				companyId={companyId}
				exception={{
					type: 'PROFF_OWNER_NOT_IN_COMPANY',
					name: user.name,
				}}
			/>
		</InfoLineWrapper>
	);
}

function CompanyOwnerNotInProff({ companyId, user }) {
	return (
		<InfoLineWrapper>
			<InfoValue>
				<div>
					<em>{user.name}</em> skal ikke være rettighetshaver.
				</div>
			</InfoValue>

			<ArchiveButton
				companyId={companyId}
				exception={{
					type: 'COMPANY_OWNER_NOT_IN_PROFF',
					name: user.name,
				}}
			/>
		</InfoLineWrapper>
	);
}

function ArchiveButton({ companyId, exception }) {
	const { notify } = useSnackbar();

	const [addProffDataDeviation, { loading, error }] = useMutation(
		ADD_PROFF_DATA_DEVIATION_EXCEPTION,
		{
			refetchQueries: ['findOneTenant'],
		}
	);

	const handleClick = async () => {
		const {
			data: { archived },
		} = await addProffDataDeviation({
			variables: {
				_id: companyId,
				exception,
			},
		});

		if (archived) {
			notify('Avviket ble arkivert!');
		}
	};

	return (
		<>
			<Tooltip title="Arkiver" placement="top" arrow>
				<div>
					<IconButton
						aria-label="Arkiver"
						disabled={loading}
						onClick={handleClick}
						size="small"
						variant="outlined"
					>
						<Icon
							icon="box-archive"
							size="sm"
							className="button-icon"
							duotone
						/>
					</IconButton>
				</div>
			</Tooltip>

			{error && <ErrorTextMessage>{error.toString()}</ErrorTextMessage>}
		</>
	);
}

function CreateUserWithRoleButton({ companyId, user }) {
	const [openDialog, setOpenDialog] = useState(false);

	return (
		<>
			<CreateUserIconButton onClick={() => setOpenDialog(true)} />
			<UsersCreateDialog
				companyId={companyId}
				user={user}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				onSubmitSuccess={() => setOpenDialog(false)}
				extraFormFields={
					<UserRolesField
						companyId={companyId}
						role={{
							value: user.role,
							title: user.title,
						}}
					/>
				}
			/>
		</>
	);
}

function AddRoleToUserButton({ companyId, user }) {
	const { notify } = useSnackbar();
	const [openDialog, setOpenDialog] = useState(false);

	const { data: { user: existingUser } = { user: {} } } = useQuery(
		FIND_USER_WITH_USER_ROLES,
		{
			variables: {
				_id: user?.userId,
			},
			skip: !user?.userId, // Skip the query if user.userId is not defined
		}
	);

	const [updateOneUser] = useMutation(UPDATE_ONE_USER, {
		refetchQueries: ['findOneTenant'],
		fetchPolicy: 'no-cache',
	});

	async function onUpdate(newRole) {
		const existingUserRoles = existingUser?.userRoles || [];
		const updatedUserRoles = [
			...existingUserRoles,
			{ role: newRole.role, id: companyId },
		];

		const res = await updateOneUser({
			variables: {
				_id: user.userId,
				userRoles: updatedUserRoles.map(ur => {
					return {
						id: ur.id,
						role: ur.role,
					};
				}),
			},
		});

		if (res.data.user) {
			notify('Bedriftsrolle ble oppdatert!');
		} else {
			notify('Noe gikk galt og bedriftsrolle kunne ikke settes!');
		}
	}

	if (user && !existingUser) {
		return null;
	}

	const list = {
		label: 'Bruker',
		options: user.role
			? [
					{
						value: user.userId,
						label: user.name,
					},
				]
			: [],
	};

	return (
		<>
			<AddUserIconButton onClick={() => setOpenDialog(true)} />

			<AddCompanyRoleDialog
				companyId={companyId}
				open={openDialog}
				list={list}
				user={user}
				onClose={() => setOpenDialog(false)}
				onSubmit={onUpdate}
			/>
		</>
	);
}

function CreateUserAsOwnerButton({ companyId, user }) {
	const [openDialog, setOpenDialog] = useState(false);

	return (
		<>
			<CreateUserIconButton onClick={() => setOpenDialog(true)} />

			<AddOwnerDialog
				companyId={companyId}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				onlyCreatUser={true}
				user={user}
			/>
		</>
	);
}

function AddUserAsOwnerButton({ companyId, user }) {
	const [openDialog, setOpenDialog] = useState(false);

	return (
		<>
			<AddUserIconButton onClick={() => setOpenDialog(true)} />

			<AddOwnerDialog
				companyId={companyId}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				user={user}
			/>
		</>
	);
}

function CreateUserIconButton({
	title = 'Opprett bruker og legg til',
	ariaLabel = 'Legg til person',
	onClick,
}) {
	return (
		<Tooltip title={title} placement="top" arrow>
			<IconButton aria-label={ariaLabel} onClick={onClick}>
				<Icon
					icon="user-plus"
					size="sm"
					className="button-icon"
					duotone
				/>
			</IconButton>
		</Tooltip>
	);
}

function AddUserIconButton({
	title = 'Legg til eksisterende bruker',
	ariaLabel = 'Legg til eksisterende bruker',
	onClick,
}) {
	return (
		<Tooltip title={title} placement="top" arrow>
			<IconButton aria-label={ariaLabel} onClick={onClick}>
				<Icon
					icon="circle-plus"
					size="sm"
					className="button-icon"
					duotone
				/>
			</IconButton>
		</Tooltip>
	);
}
