import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

import TaskDeadlineEntryTooltip from './TaskDeadlineEntryTooltip';

import { formatDate } from 'utils/dates';
import { assigmentTaskDeadlineEntryStatusColor } from 'utils/colors';

const TimelineEntryWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100%;
`;

const TimelineEntryButton = styled.button`
	background-color: transparent;
	border: 1px solid
		${({ $status, $hasCompletedSubTasks, $isOverdue }) =>
			assigmentTaskDeadlineEntryStatusColor({
				status: $status,
				hasCompletedSubTasks: $hasCompletedSubTasks,
				isOverdue: $isOverdue,
				paletteType: 'dark',
			})};
	border-radius: 3px;
	cursor: pointer;
	line-height: 1;
	opacity: ${p => (p.$notRelevant ? 0.3 : 1)};
	position: relative;
	width: 34px;
	padding: 0;
`;

const EntryMonth = styled.div`
	background: ${({ $status, $hasCompletedSubTasks, $isOverdue }) =>
		assigmentTaskDeadlineEntryStatusColor({
			status: $status,
			hasCompletedSubTasks: $hasCompletedSubTasks,
			isOverdue: $isOverdue,
			paletteType: 'dark',
		})};
	font-size: 9px;
	padding: 3px 0;
	text-align: center;
	text-transform: uppercase;
`;

const EntryDay = styled.div`
	font-size: 11px;
	padding: 2px 3px;
	text-align: center;
`;

export default function TaskDeadlineEntryIcon({ deadlineEntry, onClick }) {
	const {
		date,
		status,
		isOverdue,
		postponedStatus,
		hasCompletedSubTasks,
		files,
		comments,
	} = deadlineEntry ?? {
		files: [],
		comments: [],
	};

	const day = formatDate(date, 'DD');
	const month = formatDate(date, 'MMM');
	const notRelevant = status === 'Ikke aktuell';

	return (
		<TimelineEntryWrapper>
			<TimelineEntryButton
				$notRelevant={notRelevant}
				$status={status}
				$hasCompletedSubTasks={hasCompletedSubTasks}
				$isOverdue={isOverdue}
				onClick={onClick}
			>
				<TaskDeadlineEntryTooltip files={files} comments={comments}>
					<EntryMonth
						$status={status}
						$hasCompletedSubTasks={hasCompletedSubTasks}
						$isOverdue={isOverdue}
					>
						{month}
					</EntryMonth>

					<EntryDay>{day}</EntryDay>

					<DeadlineEntryStatusIcon
						status={status}
						hasCompletedSubTasks={hasCompletedSubTasks}
						isOverdue={isOverdue}
						postponedStatus={postponedStatus}
					/>
				</TaskDeadlineEntryTooltip>
			</TimelineEntryButton>
		</TimelineEntryWrapper>
	);
}

const StatusIconWrapper = styled.div`
	background: ${p => p.theme.palette.common.white};
	color: ${({ $status, $hasCompletedSubTasks, $isOverdue }) =>
		assigmentTaskDeadlineEntryStatusColor({
			status: $status,
			hasCompletedSubTasks: $hasCompletedSubTasks,
			isOverdue: $isOverdue,
			paletteType: 'dark',
		})};
	border-radius: 50%;
	font-weight: bold;
	font-size: 20px;
	height: 22px;
	width: 22px;
	position: absolute;
	top: -10px;
	right: -13px;
`;

function DeadlineEntryStatusIcon({ status, isOverdue, postponedStatus }) {
	if (status === 'Kontrollert') {
		return (
			<StatusIconWrapper $status={status} $isOverdue={isOverdue}>
				<CheckIcon fontSize="inherit" />
			</StatusIconWrapper>
		);
	} else if (postponedStatus) {
		return (
			<StatusIconWrapper $status={status} $isOverdue={isOverdue}>
				<AccessTimeFilledIcon fontSize="inherit" />
			</StatusIconWrapper>
		);
	}

	return null;
}
