import { useState } from 'react';
import styled from 'styled-components';
import { saveAs } from 'file-saver';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { useSnackbar } from 'web/contexts/SnackbarContext';
import { useConfirmDialog } from 'components/contexts/ConfirmContext';

import IDDocumentUploadDialog from 'web/screens/dialogs/users/IDDocumentUploadDialog';

import ErrorMessage from 'components/ErrorMessage';

import {
	REQUEST_ID_DOCUMENT_DOWNLOAD_URL,
	DELETE_ID_DOCUMENT,
} from 'api/mutations/userMutations';

const Wrapper = styled.div`
	margin-bottom: 20px;
`;

const Content = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	gap: 10px;
`;

export default function IdentityVerification({
	userId,
	fileName = null,
	onCompleted = () => {},
}) {
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const [showDialog, setShowDialog] = useState(false);

	const [
		requestIdDocumentDownloadUrl,
		{ loading: downloadUrlLoading, error: downloadUrlError },
	] = useMutation(REQUEST_ID_DOCUMENT_DOWNLOAD_URL);

	const [
		deleteIdDocument,
		{ loading: deleteDocLoading, error: deleteDocError },
	] = useMutation(DELETE_ID_DOCUMENT, {
		refetchQueries: ['findOneUser'],
	});

	return (
		<Wrapper>
			<h3>Indentifikasjonsdokument</h3>

			<ErrorMessage errors={[downloadUrlError, deleteDocError]} />

			<Content>
				{fileName && (
					<Button
						title="Last ned ID-dokumentet"
						disabled={downloadUrlLoading || downloadUrlError}
						onClick={async e => {
							try {
								e.preventDefault();

								const {
									data: { downloadUrl },
								} = await requestIdDocumentDownloadUrl({
									variables: {
										userId,
									},
								});

								if (downloadUrl) {
									saveAs(
										await (await fetch(downloadUrl)).blob(),
										fileName
									);
								}
							} catch (err) {
								console.error(err);
							}
						}}
						startIcon={<CloudDownloadIcon />}
					>
						{fileName}
					</Button>
				)}

				<ButtonWrapper>
					{fileName && (
						<Button
							disabled={deleteDocLoading}
							onClick={async () => {
								try {
									if (
										!(await verify({
											title: 'Slett ID-dokument',
											text: `Er du sikker på at du vil slette ID-dokumentet?`,
										}))
									) {
										return;
									}

									const {
										data: { deleted },
									} = await deleteIdDocument({
										variables: {
											userId,
										},
									});

									if (deleted) {
										notify('ID-dokument ble slettet');
									}
								} catch (err) {
									console.error(err);
								}
							}}
						>
							Slett
						</Button>
					)}

					<Button
						variant="outlined"
						onClick={() => setShowDialog(true)}
					>
						{fileName
							? 'Last opp nytt ID-dokument'
							: 'Last opp ID-dokument'}
					</Button>
				</ButtonWrapper>
			</Content>

			<IDDocumentUploadDialog
				userId={userId}
				open={showDialog}
				onClose={() => {
					setShowDialog(false);
					onCompleted();
				}}
			/>
		</Wrapper>
	);
}
