import {
	CalendarHeader,
	YearRow,
	YearCell,
	MonthRow,
	MonthCell,
} from '../Layout';

import { currentYear, currentMonth, months } from '../../utils.js';
import { useCalendarTimeline } from '../../context/CalendarTimelineContext';

export default function TimelineHeader() {
	const { years } = useCalendarTimeline();
	return (
		<CalendarHeader>
			<YearRow>
				{years.map(year => (
					<YearCell key={year} $current={year === currentYear}>
						{year}
					</YearCell>
				))}
			</YearRow>

			<MonthRow>
				{years.map(year =>
					months.map((month, monthIndex) => (
						<MonthCell
							key={`${year}-${month.short}`}
							$current={
								year === currentYear &&
								monthIndex === currentMonth
							}
							title={`${month.long} ${year}`}
						>
							{month.short}
						</MonthCell>
					))
				)}
			</MonthRow>
		</CalendarHeader>
	);
}
