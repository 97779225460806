import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
	Dialog,
	DialogTitle,
	DialogCloseButton,
	DialogActions,
	DialogContent,
	Button,
} from 'components/dialogs/DialogBox';

import { companyRoles } from 'server/libs/companyRoles';

import Form, { Select, AutoComplete, FormGroup } from 'web/form/Form';
import { UserCreateDialogForm } from 'web/screens/dialogs/users/UsersCreateDialog';
import AutocompleteOptionWithTooltip from 'web/components/inputs/AutocompleteOptionWithTooltip';
import CompanyRolesAutocomplete from 'web/components/inputs/CompanyRolesAutocomplete';
import AddUserTypeToggle, {
	types,
} from 'web/components/inputs/AddUserTypeToggle';

export default function AddCompanyRoleDialog({
	companyId,
	list,
	user,
	open,
	onClose,
	onSubmit,
	onCreateUser,
}) {
	const [type, setType] = useState(types.EXISTING);
	const handleTypeChange = (event, newType) => {
		if (newType !== null) {
			setType(newType);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="sm"
			aria-labelledby="dialog-title"
			aria-describedby="dialog-description"
		>
			<DialogTitle id="dialog-title">Legg til bedriftsrolle</DialogTitle>

			<DialogCloseButton onClick={onClose} />

			{!!onCreateUser && (
				<AddUserTypeToggle
					type={type}
					handleTypeChange={handleTypeChange}
				/>
			)}

			{type === types.EXISTING && (
				<AddCompanyRoleForm
					companyId={companyId}
					onClose={onClose}
					list={list}
					user={user}
					onSubmit={onSubmit}
				/>
			)}

			{type === types.NEW && (
				<UserCreateDialogForm
					onClose={onClose}
					onSubmitSuccess={onCreateUser}
					extraFormFields={<UserRolesField companyId={companyId} />}
				/>
			)}
		</Dialog>
	);
}

function AddCompanyRoleForm({ list, user, onClose, onSubmit, loading }) {
	const handleSubmit = value => {
		const { user: id, role } = value;
		const newRole = {
			id,
			role: role,
		};

		onSubmit(newRole);
		onClose();
	};

	const defaultValues = user
		? {
				user: user.userId,
				role: user.role,
			}
		: {
				user: null,
				role: null,
			};

	return (
		<Form values={defaultValues} onSubmit={value => handleSubmit(value)}>
			<DialogContent>
				<FormGroup>
					<AutoComplete
						name="user"
						label={list?.label || 'Bruker'}
						options={list?.options || []}
						getOptionKey={option => option.value}
						renderOption={(props, option) => (
							<AutocompleteOptionWithTooltip
								{...props}
								key={`userId-${option.value}`}
								option={option}
							/>
						)}
						required
					/>
				</FormGroup>

				<FormGroup>
					<Select
						label="Rolle"
						name="role"
						options={companyRoles}
						fullWidth
						required
					/>
				</FormGroup>
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>Avbryt</Button>

				<Button type="submit" variant="contained" disabled={loading}>
					Legg til
				</Button>
			</DialogActions>
		</Form>
	);
}

export function UserRolesField({
	companyId,
	role = {
		title: '',
		value: '',
	},
}) {
	const {
		setValue,
		register,
		clearErrors,
		formState: { errors },
	} = useFormContext();

	const defaultRegisterValue = role?.value
		? [
				{
					role: role.value,
					id: companyId,
				},
			]
		: '';

	// Registering field this way, because we want to set value manually to control the format
	register('userRoles', { value: defaultRegisterValue, required: true });

	const defaultValue = role?.value
		? [
				{
					value: role.value,
					label: role.title,
				},
			]
		: [];

	return (
		<>
			<CompanyRolesAutocomplete
				defaultValue={defaultValue}
				onChange={roles => {
					if (!roles || roles.length === 0) {
						setValue('userRoles', null);
						return;
					}

					if (errors.userRoles) {
						clearErrors('userRoles');
					}

					setValue(
						'userRoles',
						roles.map(role => {
							return {
								role: role.value,
								id: companyId,
							};
						})
					);
				}}
				hasError={!!errors.userRoles}
			/>
		</>
	);
}
