import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import CompanyRolesPanel from 'web/components/panels/CompanyRolesPanel';

import { FIND_TENANTS } from 'api/queries/tenantQueries';

import { useSnackbar } from 'web/contexts/SnackbarContext';

export default function UserRoles({
	user,
	updateOneUser,
	updateOneUserLoading,
}) {
	const { notify } = useSnackbar();

	const { data: { companies } = { companies: [] } } = useQuery(FIND_TENANTS, {
		variables: { orderBy: 'name', order: 1 },
	});

	const leftSideOptions = useMemo(() => {
		return companies?.map(company => ({
			value: company._id,
			label: company.name,
			navigateTo: `/bedrifter/${company._id}/rediger`,
		}));
	}, [companies]);

	async function onUpdate(updatedUserRoles) {
		const res = await updateOneUser({
			variables: {
				_id: user._id,
				userRoles: updatedUserRoles.map(ur => {
					return {
						id: ur.id,
						role: ur.role,
					};
				}),
			},
		});

		if (res.data.user) {
			notify('Bedriftsroller ble oppdatert!');
		}
	}

	if (!companies) {
		return null;
	}

	return (
		<CompanyRolesPanel
			existingUserRoles={user?.userRoles}
			listLabel="Bedrift"
			listOptions={leftSideOptions}
			onUpdate={onUpdate}
			loading={updateOneUserLoading}
		/>
	);
}
