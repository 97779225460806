import styled from 'styled-components';

import TenantRoles from 'web/components/user/TenantRoles';
import UserRoles from './UserRoles';

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 16px;
	padding: 32px 0;

	@media (min-width: 1200px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media (min-width: 1800px) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

export default function UserTenantRoles({
	user,
	updateOneUser,
	updateOneUserLoading,
}) {
	if (!user) return null;

	const tenantRoles = [
		{
			title: 'Regnskapsfører',
			tenants: user.accountantTenants,
		},
		{
			title: 'Oppdragsansvarlig',
			tenants: user.projectManagerTenants,
		},
		{
			title: 'Kundenasvarlig',
			tenants: user.accountManagerTenants,
		},
		{
			title: 'Lønnsansvarlig regnskapsfører',
			tenants: user.payrollManagerTenants,
		},
		{
			title: 'Årsoppgjørsansvarlig',
			tenants: user.annualAccountsManagerTenants,
		},
		{
			title: 'Rådgiver',
			tenants: user.advisorTenants,
		},
		{
			title: 'Oppdragskontrollør',
			tenants: user.controllerTenants,
		},
		{
			title: 'Revisor',
			tenants: user.auditorTenants,
		},
	];

	return (
		<Grid>
			{tenantRoles.map(({ title, tenants }, index) => (
				<TenantRoles
					title={title}
					tenants={tenants}
					key={`tenant-roles-${index}`}
				/>
			))}

			<TenantRoles
				title="Reell rettighesthaver"
				tenants={user.ownerTenants}
				showOwnerShare={true}
				userId={user._id}
			/>

			<UserRoles
				user={user}
				updateOneUser={updateOneUser}
				updateOneUserLoading={updateOneUserLoading}
			/>
		</Grid>
	);
}
