import { useState } from 'react';
import Button from '@mui/material/Button';
import NewPasswordDialog from 'web/screens/dialogs/users/NewPasswordDialog';

export default function NewPassword({ userId }) {
	const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);

	return (
		<>
			<Button
				variant="outlined"
				color="primary"
				size="large"
				type="button"
				onClick={() => {
					setShowNewPasswordModal(true);
				}}
			>
				Endre passord
			</Button>

			<NewPasswordDialog
				userId={userId}
				open={showNewPasswordModal}
				onClose={() => setShowNewPasswordModal(false)}
			/>
		</>
	);
}
