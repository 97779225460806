import {
	createContext,
	useContext,
	useState,
	useEffect,
	useCallback,
} from 'react';

import { currentMonth } from '../utils';
import { columnWidth } from '../timeline/Layout';

const monthsInYear = 12;
const currenMonthScrollPosition =
	(monthsInYear + currentMonth - 3) * columnWidth; // 3 month before current month

const CalendarTimelineContext = createContext();

export const useCalendarTimeline = () => useContext(CalendarTimelineContext);

export default function CalendarTimelineProvider({
	companyId,
	tasksGroupedByCategory,
	showOnlyOverdue,
	setShowOnlyOverdue,
	children,
}) {
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [scrollPosition, setScrollPosition] = useState({
		x: currenMonthScrollPosition,
		y: 0,
	});

	const years = [selectedYear - 1, selectedYear, selectedYear + 1];

	// When showOnlyOverdue is toggled, we need to scroll to the first overdue task
	// Each task date with overdue deadline is added to tasksDeadlineDates array
	const [tasksDeadlineDates, setTasksDealineDates] = useState([]);

	// Set the new scroll position to the first overdue task date
	const setNewScrollPosition = useCallback(() => {
		if (tasksDeadlineDates.length === 0) {
			return;
		}

		const oldestDeadlineDate = new Date(
			Math.min(...tasksDeadlineDates.map(date => new Date(date)))
		);

		const oldestDeadlineYear = oldestDeadlineDate.getFullYear();
		setSelectedYear(oldestDeadlineYear);

		const oldestDeadlineMonth = oldestDeadlineDate.getMonth();
		const newScrollPosition =
			(monthsInYear + oldestDeadlineMonth - 3) * columnWidth;
		setScrollPosition({ x: newScrollPosition, y: 0 });
	}, [setScrollPosition, setSelectedYear, tasksDeadlineDates]);

	useEffect(() => {
		setNewScrollPosition();
	}, [setNewScrollPosition]);

	return (
		<CalendarTimelineContext.Provider
			value={{
				companyId,
				tasksGroupedByCategory,
				showOnlyOverdue,
				setShowOnlyOverdue,
				years,
				selectedYear,
				setSelectedYear,
				scrollPosition,
				setScrollPosition,
				setTasksDealineDates,
			}}
		>
			{children}
		</CalendarTimelineContext.Provider>
	);
}
