const companiesArchiveTemplates = [
	{
		label: 'Standard',
		value: {
			name: true,
			skattemelding: true,
			arsregnskap: true,
			kvalitetskontroll: true,
			arsoppgjorkontroll: true,
			hvitvasking: true,
			avvik: true,
		},
	},
	{
		label: 'Lønn',
		value: {
			name: true,
			Lønnskjøring: true,
			'A-melding': true,
			'Avstemt skattetrekk': true,
			'Avstemt arbeidsgiveravgift': true,
			'Betalt skattetrekk og arb.g.avg.': true,
			Feriepenger: true,
			'Sammenstillingsoppg.': true,
		},
	},
	{
		label: 'Regnskap',
		value: {
			name: true,
			Rapportering: true,
			'Avstemt mva.': true,
			'Innsendt mva.': true,
			Bank: true,
			Kundefordringer: true,
			Leverandørgjeld: true,
			kvalitetskontroll: true,
		},
	},
	{
		label: 'Årsoppgjør',
		value: {
			name: true,
			skattemelding: true,
			arsregnskap: true,
			'Aksjonærregisteroppg.': true,
			'Avholdt Generalforsamling': true,
			arsoppgjorkontroll: true,
		},
	},
	{
		label: 'Oppdragsansvarlig',
		value: {
			name: true,
			Rapportering: true,
			hvitvasking: true,
			arsoppgjorkontroll: true,
			kvalitetskontroll: true,
			'Oppdragskontroll, oppdragsansvarlig': true,
			skattemelding: true,
			arsregnskap: true,
			avvik: true,
		},
	},
];

/**
 * Make visibility templates for the companies archive based on the companiesArchiveTemplates array
 * The value array is sorted based on the order of the template so the columns are displayed in the correct order
 *
 * @param {*} columns - An array of columns available in the table
 * @returns {Array} - An array of visibility templates
 */
export const getCompaniesArchiveTemplates = columns => {
	return companiesArchiveTemplates.map(template => {
		const updatedValue = { ...template.value };

		// Add missing keys with a value of false
		columns.forEach(column => {
			if (!(column.field in updatedValue)) {
				updatedValue[column.field] = false;
			}
		});

		// Extract keys with true values in the order they appear in the template
		const trueKeys = Object.keys(template.value).filter(
			key => template.value[key]
		);

		// Extract keys with false values
		const falseKeys = Object.keys(updatedValue).filter(
			key => !updatedValue[key]
		);

		// Combine true keys and false keys
		const sortedKeys = [...trueKeys, ...falseKeys];

		// Create a new sorted value object
		const sortedValue = {};
		sortedKeys.forEach(key => {
			sortedValue[key] = updatedValue[key];
		});

		return {
			...template,
			value: sortedValue,
		};
	});
};
