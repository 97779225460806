import styled from 'styled-components';
import { Link } from 'react-router';

import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';

import { useCalendarTimeline } from '../context/CalendarTimelineContext';

const EmptyCalendarTimelineWrapper = styled.div`
	text-align: center;
	padding: 20px;
`;

const EmptyCalendarTimelineMessage = styled.div`
	font-style: italic;
	margin-bottom: 10px;
`;

export default function EmptyCalendarTimeline() {
	const { companyId, showOnlyOverdue, setShowOnlyOverdue } =
		useCalendarTimeline();

	if (showOnlyOverdue) {
		return (
			<EmptyCalendarTimelineWrapper>
				<EmptyCalendarTimelineMessage>
					Ingen forfalte oppgaver
				</EmptyCalendarTimelineMessage>

				<Button
					variant="outlined"
					onClick={() => setShowOnlyOverdue(false)}
				>
					Vis alle
				</Button>
			</EmptyCalendarTimelineWrapper>
		);
	}

	return (
		<EmptyCalendarTimelineWrapper>
			<EmptyCalendarTimelineMessage>
				Ingen planlagte oppgaver
			</EmptyCalendarTimelineMessage>

			<Button
				variant="outlined"
				size="small"
				startIcon={<EditIcon />}
				component={Link}
				to={`/bedrifter/${companyId}/oppdrag/planlegg/`}
			>
				Planlegg
			</Button>
		</EmptyCalendarTimelineWrapper>
	);
}
