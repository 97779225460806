import { useQuery } from '@apollo/client';

import TaskDeadlineEntryIcon from 'web/components/assignmentTasks/TaskDeadlineEntryIcon';
import { GET_TASK_DEADLINE_ENTRY_IN_PERIOD } from 'api/queries/companyAssignmentTaskQueries';

export default function TaskDeadlineEntryInPeriod({
	companyId,
	taskName,
	month,
	year,
	setEditAssignmentEntry,
}) {
	const {
		data: { deadlineEntry } = { deadlineEntry: null },
		loading,
		error,
	} = useQuery(GET_TASK_DEADLINE_ENTRY_IN_PERIOD, {
		variables: {
			companyId,
			taskName,
			month,
			year,
		},
	});

	if (error) return <div>Feil ved lasting</div>;

	if (loading || !deadlineEntry) {
		return <div />;
	}

	return (
		<TaskDeadlineEntryIcon
			deadlineEntry={deadlineEntry}
			onClick={() => setEditAssignmentEntry(deadlineEntry)}
		/>
	);
}
