import { useMutation } from '@apollo/client';

import Button from '@mui/material/Button';

import Icon from 'components/Icon';

import { CANCEL_ALL_SIGNATURE_REQUESTS } from 'api/mutations/signatureMutations';
import { useConfirmDialog } from 'components/contexts/ConfirmContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

export default function CancelSigning({ documentId }) {
	const { verify } = useConfirmDialog();
	const { notify } = useSnackbar();
	const [
		cancelAllSignatureRequests,
		{ loading: cancelAllSignatureRequestsLoading },
	] = useMutation(CANCEL_ALL_SIGNATURE_REQUESTS, {
		refetchQueries: ['paginateDocumentNodes'],
	});

	const onCancelAllSignatureRequests = async () => {
		try {
			if (
				!(await verify({
					title: 'Kansellere alle signeringer',
					text: 'Er du sikker på at du vil kansellere signaturforespørselen?',
				}))
			) {
				return;
			}

			const { data } = await cancelAllSignatureRequests({
				variables: {
					documentId,
				},
			});

			if (data?.canceled) {
				notify('Alle signeringer ble kansellert');
			}
		} catch (err) {
			notify('Noe gikk galt under kansellering av signeringer', 'error');
			console.error(err);
		}
	};

	if (!documentId) {
		return null;
	}

	return (
		<Button
			disabled={cancelAllSignatureRequestsLoading}
			onClick={onCancelAllSignatureRequests}
			startIcon={<Icon icon="ban" />}
			variant="outlined"
			color="error"
			size="small"
		>
			Kanseller signeringer
		</Button>
	);
}
