import { nbNO } from '@mui/x-data-grid/locales';

import SortIcon from '../SortIcon';

export const localeText = nbNO.components.MuiDataGrid.defaultProps.localeText;

export let slotIcons = {
	columnSortedDescendingIcon: () => <SortIcon order={-1} />,
	columnSortedAscendingIcon: () => <SortIcon order={1} />,
	columnUnsortedIcon: () => <SortIcon order={0} />,
};

export let slotProps = {
	loadingOverlay: {
		variant: 'skeleton',
		noRowsVariant: 'skeleton',
	},
	columnsManagement: {
		disableShowHideToggle: true,
	},
};

export let sortingProps = {
	sortingOrder: ['asc', 'desc'],
	sortingMode: 'server',
};
