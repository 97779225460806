import styled from 'styled-components';

export const DocumentTitleWrapper = styled.div`
	text-align: center;
	margin-bottom: 34px;
	padding: 0 16px 16px;
`;

export const DocumentTitle = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 5px;
`;

// TODO: Only used in app and can be removed when mobile code is removed.
export const ParentTitle = styled.span`
	font-size: 13px;
	color: ${p => p.theme.palette.text.light};
	display: block;
	width: 100%;
`;

export const DocumentPath = styled.div`
	background-color: ${p => p.theme.palette.background.gray};
	border: 1px solid ${p => p.theme.palette.background.paper};
	color: ${p => p.theme.palette.text.light};
	display: inline;
	font-size: 13px;
	padding: 5px 15px;
	width: 100%;
`;

export const DocumentParagraph = styled.p`
	margin-bottom: 0;
	text-align: ${p => p.$align || 'left'};
	font-size: 18px;
	line-height: 30px;
`;
