import TaskDeadlines from './taskDeadlines/TaskDeadlines';

import { TimelineContentWrapper, TimelineGrid, GroupCell } from '../Layout';

import { useCalendarTimeline } from '../../context/CalendarTimelineContext';

export default function TimelineContent() {
	const { tasksGroupedByCategory } = useCalendarTimeline();

	return (
		<TimelineContentWrapper>
			<TimelineGrid>
				{tasksGroupedByCategory.map((category, assignmentIndex) => (
					<CategoryGroup key={assignmentIndex} category={category} />
				))}
			</TimelineGrid>
		</TimelineContentWrapper>
	);
}

function CategoryGroup({ category }) {
	return (
		<>
			<GroupCell />
			{category.tasks.map(task => (
				<TaskDeadlines key={task._id} taskId={task._id} />
			))}
		</>
	);
}
