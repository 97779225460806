import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import TaskDeadlineEntryIcon from 'web/components/assignmentTasks/TaskDeadlineEntryIcon';
import { GET_TASK_DEADLINE_ENTRIES_IN_YEAR } from 'api/queries/companyAssignmentTaskQueries';

const EntriesWrapper = styled.div`
	display: flex;
	gap: 4px;
	align-items: center;
	justify-content: center;
`;

export default function TaskDeadlineEntriesInYear({
	companyId,
	taskName,
	year,
	setEditAssignmentEntry,
}) {
	const {
		data: { deadlineEntries } = { deadlineEntries: null },
		loading,
		error,
	} = useQuery(GET_TASK_DEADLINE_ENTRIES_IN_YEAR, {
		variables: {
			companyId,
			taskName,
			year,
		},
	});

	if (error) return <div>Feil ved lasting</div>;

	if (loading || !deadlineEntries || !deadlineEntries.length) {
		return <div />;
	}

	return (
		<EntriesWrapper>
			{deadlineEntries.map(deadlineEntry => (
				<Entry
					key={deadlineEntry._id}
					deadlineEntry={deadlineEntry}
					setEditAssignmentEntry={setEditAssignmentEntry}
					//showDialog={showDialog}
				/>
			))}
		</EntriesWrapper>
	);
}

function Entry({ deadlineEntry, setEditAssignmentEntry }) {
	return (
		<>
			<TaskDeadlineEntryIcon
				deadlineEntry={deadlineEntry}
				onClick={() => setEditAssignmentEntry(deadlineEntry)}
			/>
		</>
	);
}
