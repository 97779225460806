import { Controller, useFormContext } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MUISelect from '@mui/material/Select';

export default function Select({
	name,
	label,
	defaultValue = null,
	options = [],
	fullWidth = false,
	size = 'normal',
	required = false,
	...props
}) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue ?? ''}
			render={({ field: { value, ...fieldProps } }) => {
				return (
					<div>
						<FormControl fullWidth={fullWidth} required={required}>
							<InputLabel id={`${name}-select-label`} size={size}>
								{label}
							</InputLabel>

							<MUISelect
								labelId={`${name}-select-label`}
								id={`${name}-select`}
								value={value ?? ''}
								label={label}
								sx={{ minWidth: '400px' }}
								size={size}
								{...fieldProps}
								{...props}
							>
								{options.map((option, index) => (
									<MenuItem key={index} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</MUISelect>
						</FormControl>
					</div>
				);
			}}
		/>
	);
}
