import { useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import { formatDate } from 'utils/dates';

import ErrorAlert from 'components/ErrorAlert';

import { INVITE_ONE_USER } from 'api/mutations/userMutations';
import { useSnackbar } from 'web/contexts/SnackbarContext';

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: minmax(0, 400px) auto;
	align-items: center;
	gap: 20px;
	margin-bottom: 20px;
`;

const ErrorWrapper = styled.div`
	width: 100%;
	max-width: 700px;
`;

export default function ResendInvite({ user }) {
	const { notify } = useSnackbar();

	const [inviteSent, setInviteSent] = useState(false);

	const [inviteOneUser, { loading, error }] = useMutation(INVITE_ONE_USER, {
		refetchQueries: ['findOneUser'],
	});

	const userInvite =
		(user.userVerifications &&
			user.userVerifications.find(
				verification => verification.type == 'invite'
			)) ||
		null;

	return (
		<>
			<Wrapper>
				<Button
					variant="outlined"
					size="large"
					disabled={loading || inviteSent}
					onClick={async () => {
						try {
							const { data } = await inviteOneUser({
								variables: {
									_id: user._id,
								},
							});

							if (data.inviteOneUser) {
								setInviteSent(true);

								notify('Bruker ble invitert');
							}
						} catch (err) {
							console.error(err);
						}
					}}
					startIcon={<SendIcon />}
				>
					{loading ? 'Inviterer…' : 'Inviter bruker'}
				</Button>

				{userInvite && userInvite.lastSent && (
					<div>
						<strong>Sist invitert: </strong>

						{formatDate(
							userInvite.lastSent,
							'DD.MM.YYYY [kl] HH:mm'
						)}
					</div>
				)}
			</Wrapper>

			{error && (
				<ErrorWrapper>
					<ErrorAlert error={error} />
				</ErrorWrapper>
			)}
		</>
	);
}
