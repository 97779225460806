import { useQuery } from '@apollo/client';
import { Link } from 'react-router';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { GET_DEADLINE_ENTRIES_OVERDUE_STATUS_FOR_COMPANY } from 'api/queries/companyAssignmentTaskQueries';

const Error = styled.div`
	color: ${p => p.theme.palette.status.error};
`;

const StatusIcon = styled(CalendarMonthIcon)`
	color: ${p =>
		p.$isOverdue
			? p.theme.palette.status.dark.red
			: p.theme.palette.status.dark.green};
`;

export default function TaskCalendarButton({ companyId }) {
	const {
		data: { isOverdue } = { isOverdue: false },
		loading,
		error,
	} = useQuery(GET_DEADLINE_ENTRIES_OVERDUE_STATUS_FOR_COMPANY, {
		variables: {
			companyId,
		},
	});

	if (error) {
		console.error(error);

		return <Error>Feil!</Error>;
	}

	return (
		<IconButton
			title="Gå til oppdragsutførelse"
			component={Link}
			to={`/bedrifter/${companyId}/rediger/?tab=planlegging`}
			sx={{ py: 0 }}
		>
			{loading ? (
				<CalendarMonthIcon />
			) : (
				<StatusIcon $isOverdue={isOverdue} />
			)}
		</IconButton>
	);
}
