import { useMutation } from '@apollo/client';

import Button from '@mui/material/Button';
import LockResetIcon from '@mui/icons-material/LockReset';

import { useSnackbar } from 'web/contexts/SnackbarContext';
import { RESET_FAILED_LOGIN_ATTEMPTS } from 'api/mutations/userMutations';

const errorMsg = 'Noe gikk galt og påloggingsforsøk kunne ikke nullstilles';

export default function ResetFailedLoginaAttempts({ userId }) {
	const { notify } = useSnackbar();

	const [resetFailedLoginAttempts, { loading }] = useMutation(
		RESET_FAILED_LOGIN_ATTEMPTS,
		{
			refetchQueries: ['findOneUser'],
		}
	);

	return (
		<Button
			variant="outlined"
			color="primary"
			size="large"
			type="button"
			startIcon={<LockResetIcon />}
			disabled={loading}
			onClick={async () => {
				try {
					const { data } = await resetFailedLoginAttempts({
						variables: {
							_id: userId,
						},
					});

					if (data.resetFailedLoginAttempts) {
						notify('For mange påloggingsforsøk ble nullstilt');
					} else {
						notify(errorMsg, 'error');
					}
				} catch (error) {
					console.error(error);
					notify(errorMsg, 'error');
				}
			}}
		>
			Nullstill for mange pålogginsforsøk
		</Button>
	);
}
