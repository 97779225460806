import styled from 'styled-components';
import { useNavigate } from 'react-router';
import Drawer from '@mui/material/Drawer';

import { useWebApp } from 'web/contexts/WebContexts';
import { useUser } from 'web/contexts/UserContext';
import useLocalStorage from 'hooks/useLocalStorageSetting';

import ListItemComponent, {
	StyledList,
	StyledListItem,
	MenuItem,
} from 'web/menus/ListItem';

const Nav = styled.nav`
	margin: 0;
	padding: 15px 0;
	display: flex;
	min-height: 100%;
	width: 100%;
	overflow-x: hidden;
	flex-direction: column;
	justify-content: space-between;
`;

const Divider = styled.div`
	margin: 12px 0;
	border-top: 1px solid rgba(0, 0, 0, 0.23);
`;

const StyledDrawer = styled(Drawer)`
	.MuiDrawer-paper {
		top: 68px;
		bottom: 0;
		height: calc(100% - 68px);
		width: 260px;
		z-index: 9;
		border-right: none;
		background-color: ${p => p.theme.palette.background.gray};

		@media print {
			display: none;
		}
	}
`;

export default function MainMenu({ open, onClose }) {
	let navigate = useNavigate();
	const { logout } = useWebApp();
	const { value: tenant } = useLocalStorage('tenant');
	const { user, userHasMinRole, userHasOneOfRoles } = useUser();

	const getMenuItems = () => {
		let items = [
			{
				id: 'hjem',
				label: 'Hjem',
				icon: 'home',
				to: '/',
				roles: ['auditor', 'user'],
			},
			{
				id: 'arbeidsuke',
				label: 'Min arbeidsuke',
				icon: 'calendar',
				to: '/',
				roles: ['admin', 'accountant'],
			},
			{
				id: 'statistikk',
				label: 'Statistikk',
				icon: 'rectangles-mixed',
				to: '/statistikk/',
				roles: ['admin', 'accountant'],
				subs: [
					{
						label: 'Næringskoder',
						to: '/statistikk/naeringskoder/',
						roles: ['admin', 'accountant'],
					},
				],
			},
			{
				id: 'dokumenter',
				label: 'Dokumenter',
				icon: 'folder',
				to: '/dokumenter/',
				roles: ['user'],
			},
			{
				id: 'mine-bedrifter',
				label: 'Mine bedrifter',
				icon: 'building',
				to: '/bedrifter/',
				roles: ['admin', 'accountant', 'auditor'],
				subs: [
					{
						label: 'Utsatte oppgaver',
						to: '/bedrifter/utsatte-oppgaver/',
						roles: ['admin'],
					},
					{
						label: 'Bedriftskategorier',
						to: '/bedriftskategorier/',
						roles: ['admin'],
					},
				],
			},
			{
				id: 'alle-bedrifter',
				label: 'Alle bedrifter',
				icon: 'building-magnifying-glass',
				to: '/alle-bedrifter/',
				roles: ['admin', 'accountant'],
			},
			{
				id: 'brukere',
				label: 'Brukere',
				icon: 'users',
				to: '/brukere/',
				roles: ['admin', 'accountant'],
				subs: [
					{
						label: 'Regnskapsførere',
						to: '/regnskapsforere/',
						roles: ['admin', 'accountant'],
					},
					{
						label: 'Revisorer',
						to: '/revisorer/',
						roles: ['admin', 'accountant'],
					},
					{
						label: 'Revisorfirma',
						to: '/revisorfirma/',
						roles: ['admin', 'accountant'],
					},
				],
			},
			{
				id: 'soknader',
				label: 'Søknader',
				icon: 'newspaper',
				to: '/soknader/',
				roles: ['admin'],
			},
			{
				id: 'eksport',
				label: 'Eksport',
				icon: 'download',
				to: '/eksport/',
				roles: ['admin'],
			},
			{
				id: 'hjelpesider',
				label: 'Hjelpesider',
				icon: 'square-info',
				to: '/hjelpesider/',
				roles: ['admin'],
			},
		];

		return items.filter(item => {
			if (item.subs?.length > 0) {
				item.subs = item.subs.filter(sub => {
					if (!sub.roles) return true;

					return userHasOneOfRoles(sub.roles);
				});
			}

			if (!item.roles) return true;

			return userHasOneOfRoles(item.roles);
		});
	};

	const menuItems = getMenuItems();
	const tenantUrl =
		tenant && userHasOneOfRoles(['admin', 'accountant', 'auditor'])
			? `/bedrifter/?search=${tenant.name}`
			: '/dokumenter/';
	const myProfileUrl = userHasOneOfRoles(['admin', 'accountant'])
		? `/brukere/${user._id}/`
		: `/profil/`;

	return (
		<StyledDrawer
			anchor="left"
			variant="persistent"
			sx={{
				gridArea: 'menu',
			}}
			open={open}
			onClose={onClose}
		>
			<Nav>
				<div>
					{tenant && (
						<>
							<MenuItem
								label={tenant.name}
								icon="arrow-circle-right"
								to={tenantUrl}
								preventactive="true"
							/>
							<Divider />
						</>
					)}

					{menuItems?.length > 0 && (
						<StyledList>
							{menuItems.map((item, index) => {
								return (
									<ListItemComponent
										item={item}
										key={`menu-item-${item.to}-${index}`}
									/>
								);
							})}
						</StyledList>
					)}
				</div>

				<div>
					<Divider />

					<StyledList>
						{userHasOneOfRoles('user') && (
							<StyledListItem>
								<MenuItem
									label="Mine bedrifter"
									icon="building"
									to="/mine-bedrifter/"
								/>
							</StyledListItem>
						)}

						<StyledListItem>
							<MenuItem
								label="Min profil"
								to={myProfileUrl}
								icon="user"
							/>
						</StyledListItem>

						{userHasMinRole('accountant') && (
							<StyledListItem>
								<MenuItem
									label="Hjelp/rutiner"
									to="/hjelp"
									icon="message-question"
								/>
							</StyledListItem>
						)}

						<StyledListItem>
							<MenuItem
								label="Logg ut"
								icon="sign-out-alt"
								onClick={() => {
									logout();

									navigate('/');
								}}
							/>
						</StyledListItem>
					</StyledList>
				</div>
			</Nav>
		</StyledDrawer>
	);
}
