import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

function hasOverflowHidden(element) {
	const computedStyle = window.getComputedStyle(element);
	return computedStyle.overflow === 'hidden';
}

const ScrollableElement = styled.div`
	cursor: ${p => (p.$isGrabbing ? 'grabbing' : 'grab')};
	border: 1px solid ${p => p.theme.palette.background.paper};
	overflow: auto;
	height: 85vh;
	width: 100%;
	user-select: none;
`;

export default function ScrollContainer({ jumpToPosition = null, children }) {
	const containerRef = useRef(null);
	const [isGrabbing, setIsGrabbing] = useState(false);
	const [position, setPosition] = useState({ x: 0, y: 0 });

	const handleMouseDown = e => {
		if (hasOverflowHidden(document.body)) return;

		setIsGrabbing(true);
		setPosition({ x: e.clientX, y: e.clientY });
	};

	const handleMouseMove = e => {
		if (!isGrabbing || hasOverflowHidden(document.body)) return;

		const deltaX = e.clientX - position.x;
		const deltaY = e.clientY - position.y;

		if (containerRef.current) {
			containerRef.current.scrollLeft -= deltaX;
			containerRef.current.scrollTop -= deltaY;
		}

		setPosition({ x: e.clientX, y: e.clientY });
	};

	const handleMouseUp = () => {
		setIsGrabbing(false);
	};

	useEffect(() => {
		if (!jumpToPosition) return;

		containerRef.current.scrollLeft = jumpToPosition.x;
		containerRef.current.scrollTop = jumpToPosition.y;
	}, [jumpToPosition]);

	return (
		<ScrollableElement
			ref={containerRef}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseMove={handleMouseMove}
			onMouseLeave={handleMouseUp}
			$isGrabbing={isGrabbing}
		>
			{children}
		</ScrollableElement>
	);
}
