import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';

import { pluralize } from 'utils/strings';

export default function TaskDeadlineEntryTooltip({
	files = [],
	comments = [],
	children,
}) {
	return (
		<Tooltip
			title={<TooltipText files={files} comments={comments} />}
			placement="right"
			arrow
		>
			<div>{children}</div>
		</Tooltip>
	);
}

const TooltipTextWrapper = styled.div`
	font-size: 0.8rem;
`;

function TooltipText({ files, comments }) {
	return (
		<TooltipTextWrapper>
			{pluralize('Fil', 'Filer', files)}: {files.length}
			<br />
			{pluralize('Kommentar', 'Kommentarer', comments)}: {comments.length}
		</TooltipTextWrapper>
	);
}
