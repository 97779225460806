import { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

import {
	removeFileExtension,
	getFileExtension,
	fileSlugify,
} from 'utils/strings';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorMessage from 'components/ErrorMessage';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import FileField from 'components/form/FileField';
import DateField from 'components/form/DateField';
import CheckboxField from 'components/form/CheckboxField';
import FileCreateNotificationsField from 'components/form/FileCreateNotificationsField';
import SubmitButton from 'components/form/SubmitButton';

import { GET_COMPANY_DOCUMENTS } from 'api/queries/documentQueries';
import {
	UPDATE_DOCUMENT_NODE,
	CREATE_ONE_DOCUMENT_NODE,
	NOTIFY_USERS,
} from 'api/mutations/documentMutations';

export default function DocumentCreateDialog({
	parentId,
	tenantId,
	order,
	orderBy,
	includeHidden,
	isOpen,
	close,
}) {
	return (
		<Dialog
			open={isOpen}
			fullWidth={true}
			maxWidth="xs"
			onClose={close}
			aria-labelledby="document-create-dialog-title"
		>
			<DialogTitle id="document-create-dialog-title">
				Nytt dokument
			</DialogTitle>

			<DocumentCreateForm
				parentId={parentId}
				tenantId={tenantId}
				order={order}
				orderBy={orderBy}
				includeHidden={includeHidden}
				close={close}
			/>
		</Dialog>
	);
}

function DocumentCreateForm({
	parentId,
	tenantId,
	order,
	orderBy,
	includeHidden,
	close,
}) {
	const { notify } = useSnackbar();
	const nameInputEl = useRef(null);
	const [isLoading, setIsLoading] = useState(false);

	const [createDocumentNode, { error: createDocumentNodeError }] =
		useMutation(CREATE_ONE_DOCUMENT_NODE);

	const [updateDocumentNode, { error: updateDocumentNodeError }] =
		useMutation(UPDATE_DOCUMENT_NODE, {
			refetchQueries: [
				{
					query: GET_COMPANY_DOCUMENTS,
					variables: {
						parentId,
						tenantId,
						includeHidden,
						order,
						orderBy,
					},
				},
			],
		});

	const [notifyUsers, { error: notifyUsersError }] =
		useMutation(NOTIFY_USERS);

	return (
		<Form
			isLoading={isLoading}
			isDisabled={isLoading}
			values={{
				date: new Date(),
				notifyEmail: true,
				notifySMS: false,
			}}
			onSubmit={async values => {
				setIsLoading(true);

				const { name, date, file, manuallySigned, users } = values;

				const filename = fileSlugify(
					name,
					getFileExtension(file.name),
					{
						separator: ' ',
						includeDate: true,
						dateFormat: 'DD.MM.YYYY',
						dateSeperator: ' - ',
						date: date,
						lowercase: false,
					}
				);

				let data;

				try {
					({ data } = await createDocumentNode({
						variables: {
							name: filename,
							parentId,
							size: file.size,
							contentType: file.type,
							tenantId,
							manuallySigned,
							order: 2,
						},
					}));
				} catch (error) {
					console.error(error);

					setIsLoading(false);

					return;
				}

				let documentState = data?.file?.status ?? 'error';

				if (documentState === 'uploading') {
					try {
						const response = await fetch(data.file.uploadUrl, {
							method: 'PUT',
							body: file,
							headers: {
								'Content-Type': file.type,
							},
						});

						if (!response.ok || response.status !== 200) {
							throw new Error(
								`Failed to upload file to S3 with error: (${response.status}) ${response.statusText}!`
							);
						}

						documentState = 'ready';
					} catch (err) {
						documentState = 'error';
						console.error(err);
					}
				}

				let updateResponse;

				try {
					updateResponse = await updateDocumentNode({
						variables: {
							_id: data.file._id,
							status: documentState,
						},
					});
				} catch (error) {
					console.error(error);

					setIsLoading(false);

					return;
				}

				if (
					users?.length > 0 &&
					documentState === 'ready' &&
					updateResponse?.data?.documentNode
				) {
					try {
						const { notifyEmail, notifySMS } = values;

						let channels = [];

						if (notifyEmail) channels.push('EMAIL');
						if (notifySMS) channels.push('SMS');

						await notifyUsers({
							variables: {
								documentId:
									updateResponse.data.documentNode._id,
								users,
								channels,
							},
						});
					} catch (err) {
						console.error(err);

						setIsLoading(false);

						return;
					}
				}

				setIsLoading(false);

				notify('Dokumentet ble lastet opp!');

				close();
			}}
		>
			<DialogContent>
				<ErrorMessage
					errors={[
						createDocumentNodeError,
						updateDocumentNodeError,
						notifyUsersError,
					]}
				/>

				<FileField
					required
					name="file"
					label="Velg fil"
					helperText="Filen kan maksimalt være 20MB"
					maxSize="20000000"
					onChange={(file, { updateField }) => {
						//TODO: Convert non latin to latin quickfix.
						const filename = file.name
							.replace('Å', 'Å')
							.replace('å', 'å');

						const name = removeFileExtension(filename);

						// Update the name filed with the filename without the extension.
						updateField({
							name: 'name',
							value: name,
						});

						nameInputEl.current.value = name;
						nameInputEl.current.select();
					}}
				/>

				<TextField
					inputRef={nameInputEl}
					name="name"
					label="Navngi filen hvis endring"
					required
				/>

				<DateField name="date" label="Velg dato" required />

				<CheckboxField
					name="manuallySigned"
					label="Er dette dokumentet allerede signert?"
				/>

				<FileCreateNotificationsField tenantId={tenantId} />
			</DialogContent>

			<DialogActions>
				<Button onClick={close}>Avbryt</Button>

				<SubmitButton icon={<SaveIcon />} variant="contained">
					Last opp dokument
				</SubmitButton>
			</DialogActions>
		</Form>
	);
}
