import { useAppFilters } from 'web/contexts/AppFiltersContext';

import TaskDeadlineEntryByType from 'web/components/companiesArchive/cells/TaskDeadlineEntryByType';

export default function TaskDeadlineEntryStatusByType({
	companyId,
	type,
	setEditAssignmentEntry,
}) {
	const { year } = useAppFilters();

	if (Number.isNaN(!year?.value)) {
		console.error('Missing year ');
		return <div />;
	}

	return (
		<TaskDeadlineEntryByType
			companyId={companyId}
			type={type}
			year={year.value}
			setEditAssignmentEntry={setEditAssignmentEntry}
			showDeadline
		/>
	);
}
