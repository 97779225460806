import styled from 'styled-components';

import Toolbar from './toolbar/Toolbar';
import CalendarTimeline from './timeline/CalendarTimeline';
import EmptyCalendarTimeline from './timeline/EmptyCalendarTimeline';
import { useCalendarTimeline } from './context/CalendarTimelineContext';

const CalendarTimelineHeader = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 15px;
	margin-bottom: 15px;
`;

export default function AssignmentTasksCalendarTimeline() {
	const { tasksGroupedByCategory } = useCalendarTimeline();

	return (
		<div>
			<CalendarTimelineHeader>
				<Toolbar />
			</CalendarTimelineHeader>

			{tasksGroupedByCategory.length === 0 ? (
				<EmptyCalendarTimeline />
			) : (
				<CalendarTimeline />
			)}
		</div>
	);
}
