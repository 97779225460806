import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';

import ProffDataDeviationExceptions from 'web/components/ProffDataDeviationExceptions';
import ProffDataDeviations from 'web/components/ProffDataDeviations';

import Panel from 'web/components/panels/Panel';

export default function ProffDataDeviationsPanel({
	companyId,
	deviations,
	deviationExceptions,
}) {
	const [showDeviationExceptions, setShowDeviationExceptions] =
		useState(false);

	useEffect(() => {
		if (showDeviationExceptions && deviationExceptions.length === 0) {
			setShowDeviationExceptions(false);
		}
	}, [deviationExceptions]);

	if (
		!deviations?.hasDeviations &&
		(!deviationExceptions || deviationExceptions.length === 0)
	)
		return null;

	return (
		<Panel
			title={
				showDeviationExceptions
					? 'Arkiverte avvik fra proff.no'
					: 'Avvik fra proff.no'
			}
			background={false}
		>
			{deviationExceptions && deviationExceptions.length !== 0 && (
				<Button
					onClick={() => setShowDeviationExceptions(prev => !prev)}
					sx={{ marginBottom: '10px' }}
				>
					{showDeviationExceptions
						? 'Skjul arkiverte'
						: 'Vis arkiverte'}
				</Button>
			)}

			{showDeviationExceptions ? (
				<ProffDataDeviationExceptions
					companyId={companyId}
					deviationExceptions={deviationExceptions}
				/>
			) : (
				<ProffDataDeviations
					companyId={companyId}
					deviations={deviations}
				/>
			)}
		</Panel>
	);
}
