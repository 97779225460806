import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import SaveIcon from '@mui/icons-material/Save';

import Stack from '@mui/material/Stack';

import DesktopLayout, { NarrowContent } from 'web/components/Layout';
import ErrorAlert from 'components/ErrorAlert';

import Form, { TextField, AutoComplete, Button } from 'web/form/Form';

import { INSERT_COMPANY } from 'api/mutations/tenantMutations';

export default function CompeniesCreate() {
	let navigate = useNavigate();
	const [createNewCompany, { error, loading }] = useMutation(INSERT_COMPANY);

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					to: '/bedrifter/',
					label: 'Bedrifter',
				},
				{
					label: 'Ny bedrift',
				},
			]}
		>
			<NarrowContent>
				<ErrorAlert error={error} />

				<Form
					onSubmit={async variables => {
						try {
							const { data } = await createNewCompany({
								variables: {
									...variables,
									orgNo: variables?.orgNo?.replaceAll(
										' ',
										''
									),
								},
							});

							if (data?.company?._id) {
								navigate(
									`/bedrifter/${data?.company?._id}/rediger/`
								);
							}
						} catch (err) {
							console.error(err);
						}
					}}
				>
					<Stack spacing={3}>
						<TextField
							name="name"
							type="text"
							label="Navn"
							required
						/>

						<AutoComplete
							name="accountTypes"
							label="Kontotyper"
							defaultValue={['accounting']}
							options={[
								{
									value: 'accounting',
									label: 'Regnskapskunde',
								},
								{
									value: 'counseling',
									label: 'Rådgivningskunde',
								},
								{
									value: 'lead',
									label: 'Potensiell kunde',
								},
							]}
							multiple
							required
						/>

						<TextField
							name="clientNo"
							type="text"
							label="Kundenummer"
						/>

						<TextField
							name="orgNo"
							type="text"
							label="Organisasjonsnr"
							pattern="^\s*(\d{3}\s*\d{3}\s*\d{3}|\d{9})\s*$"
						/>

						<TextField
							name="freeText"
							type="text"
							label="Fritekst"
						/>

						<Button
							sx={{ alignSelf: 'flex-end' }}
							type="submit"
							variant="contained"
							endIcon={<SaveIcon />}
							loading={loading}
						>
							Opprett bedrift
						</Button>
					</Stack>
				</Form>
			</NarrowContent>
		</DesktopLayout>
	);
}
