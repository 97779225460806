import Stack from '@mui/material/Stack';

import styled from 'styled-components';
import { UnderlineLink } from 'web/components/Link';

import Icon from 'components/Icon';

const DocumentButtonIcon = styled.span`
	margin-right: 8px;
	text-align: right;
`;

export default function ActionLinks({ sendToSigning }) {
	return (
		<Stack spacing={2} sx={{ textAlign: 'center' }}>
			<ActionLink to="rediger" icon="edit" label="Rediger dokument" />

			{sendToSigning && (
				<ActionLink
					to="signer"
					icon="file"
					label="Send til signering"
				/>
			)}

			<ActionLink to="del" icon="share-alt" label="Del med noen" />
		</Stack>
	);
}

function ActionLink({ to, icon, label }) {
	return (
		<UnderlineLink to={to}>
			<DocumentButtonIcon>
				<Icon icon={icon} />
			</DocumentButtonIcon>
			{label}
		</UnderlineLink>
	);
}
