import { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

export default function SplitButton({
	options = [],
	onClick = () => {},
	buttonGroupProps = {
		'aria-label': 'Alternativer',
	},
	buttonProps = {},
	splitButtonProps = {
		'aria-label': 'Velg alternativer',
	},
	splitMenuId = 'split-button-menu',
	defaultSelectedIndex = 0,
}) {
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);
	const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex);

	const handleClick = () => onClick(options[selectedIndex]);

	const handleMenuItemClick = (event, index) => {
		setSelectedIndex(index);
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	return (
		<>
			<ButtonGroup ref={anchorRef} {...buttonGroupProps}>
				<Button onClick={handleClick} {...buttonProps}>
					{options[selectedIndex]}
				</Button>

				<Button
					size="small"
					aria-controls={open ? splitMenuId : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-haspopup="menu"
					onClick={handleToggle}
					{...splitButtonProps}
				>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Popper
				sx={{ zIndex: 1 }}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom'
									? 'center top'
									: 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id={splitMenuId} autoFocusItem>
									{options.map((option, index) => (
										<MenuItem
											key={option}
											disabled={index === 2}
											selected={index === selectedIndex}
											onClick={event =>
												handleMenuItemClick(
													event,
													index
												)
											}
										>
											{option}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
}
