import { useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import Button from '@mui/material/Button';

import ErrorMessage from 'components/ErrorMessage';
import SearchFilter from 'components/filters/SearchFilter';
import DesktopLayout from 'web/components/Layout';
import ContentWithSidebarFilters from 'web/components/ContentWithSidebarFilters';
import { InlineLoading } from 'components/Loading';
import LoadMoreButton from 'web/components/LoadMoreButton';
import Table, { TableRow, TableCell } from 'web/components/Table';
import SortIcon from 'web/components/SortIcon';
import CompanyBasicInfoDialog from 'web/screens/dialogs/companies/CompanyBasicInfoDialog';

import useSortOrder from 'hooks/useSortOrder';
import { useAppFilters } from 'web/contexts/AppFiltersContext';

import { PAGINATE_TENANTS_WITH_BASIC_INFO } from 'api/queries/tenantQueries';
import { conjunctionFormat } from 'utils/arrays';

const SmallerText = styled.div`
	font-size: 14px;
`;

const displayFilters = [
	'department',
	'accountant',
	'projectManager',
	'accountManager',
	'payrollManager',
	'annualAccountsManager',
	'controller',
	'auditor',
];

export default function CompaniesBasicInfoSearch() {
	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Alle bedrifter',
				},
			]}
			title="Alle bedrifter"
		>
			<ContentWithSidebarFilters
				displayFilters={displayFilters}
				headerComponents={
					<SearchFilter label="Søk etter bedrift" size="small" />
				}
			>
				<Content />
			</ContentWithSidebarFilters>
		</DesktopLayout>
	);
}

function Content() {
	const { search, activeFilters } = useAppFilters();
	const [{ order, orderBy }, setNewOrder] = useSortOrder('name');

	const {
		department,
		accountant,
		projectManager,
		accountManager,
		payrollManager,
		annualAccountsManager,
		controller,
		auditor,
		company,
		accountType,
	} = activeFilters;

	// Get all companies to display in the table
	const {
		data: { companies = { items: [] } } = {
			companies: { items: [] },
		},
		fetchMore,
		loading,
		error,
	} = useQuery(PAGINATE_TENANTS_WITH_BASIC_INFO, {
		variables: {
			search,
			filter: {
				departmentId: department,
				accountantId: accountant,
				projectManagerId: projectManager,
				accountManagerId: accountManager,
				payrollManagerId: payrollManager,
				annualAccountsManagerId: annualAccountsManager,
				controllerId: controller,
				auditorId: auditor,
				company: company,
				accountType: accountType,
			},
			orderBy: 'name',
			order,
			limit: 40,
		},
	});

	const fetchMoreCompanies = () => {
		fetchMore({
			variables: {
				offset: companies.items.length,
			},
			updateQuery: (prev, { fetchMoreResult }) => {
				if (fetchMoreResult.companies.items.length === 0) {
					return prev;
				}

				const result = {
					companies: {
						...prev.companies,
						items: [
							...prev.companies.items,
							...fetchMoreResult.companies.items,
						],
					},
				};

				return result;
			},
		});
	};

	return (
		<>
			<ErrorMessage errors={[error]} />

			<Table columns="13">
				<TableHeader
					order={order}
					orderBy={orderBy}
					setNewOrder={setNewOrder}
				/>

				{loading && !companies?.items?.length && <LoadingContent />}

				{!loading && companies?.items?.length === 0 && <NoResults />}

				<TableContent companies={companies} />
			</Table>

			{companies && companies.count > companies.items.length && (
				<LoadMoreButton
					disabled={loading}
					onClick={fetchMoreCompanies}
				/>
			)}
		</>
	);
}

function TableHeader({ order, orderBy, setNewOrder }) {
	return (
		<TableRow header>
			<TableCell start="1" end="5" onClick={() => setNewOrder('name')}>
				<span>Bedrift</span>

				<SortIcon field="name" orderBy={orderBy} order={order} />
			</TableCell>

			<TableCell start="6" end="9">
				Regnskapsfører
			</TableCell>
			<TableCell start="10" end="13">
				Kundeansvarlig
			</TableCell>
		</TableRow>
	);
}

function TableContent({ companies }) {
	const [showDialog, setShowDialog] = useState(false);
	const [companyId, setCompanyId] = useState(null);

	const selectCompany = company => {
		setCompanyId(company._id);
		setShowDialog(true);
	};

	const closeDialog = () => {
		setShowDialog(false);
		// Add a little delay to avoid flickering when closing
		setTimeout(() => setCompanyId(null), 150);
	};

	return (
		<>
			{companies?.items.map(company => (
				<CompanyItem
					key={company._id}
					company={company}
					selectCompany={selectCompany}
				/>
			))}

			<CompanyBasicInfoDialog
				open={showDialog}
				companyId={companyId}
				onClose={closeDialog}
			/>
		</>
	);
}

function CompanyItem({ company, selectCompany }) {
	const { name, accountants, accountManagers } = company;
	return (
		<Button
			variant="link"
			onClick={() => selectCompany(company)}
			sx={{ padding: 0, fontSize: '16px', textAlign: 'left' }}
			disableRipple
		>
			<TableRow>
				<TableCell start="1" end="5">
					<div>{name}</div>
				</TableCell>

				<TableCell start="6" end="9">
					<SmallerText>
						{conjunctionFormat(accountants, ['name'])}
					</SmallerText>
				</TableCell>

				<TableCell start="10" end="13">
					<SmallerText>
						{conjunctionFormat(accountManagers, ['name'])}
					</SmallerText>
				</TableCell>
			</TableRow>
		</Button>
	);
}

function LoadingContent() {
	return (
		<TableRow transparent>
			<TableCell start="1" end="-1">
				<InlineLoading />
			</TableCell>
		</TableRow>
	);
}

function NoResults() {
	return (
		<TableRow transparent>
			<TableCell start="1" end="-1" center>
				Ingen bedrifter ble funnet
			</TableCell>
		</TableRow>
	);
}
