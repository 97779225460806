import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';

import SaveIcon from '@mui/icons-material/Save';

import { roles } from 'server/context/roles';
import { countries } from 'utils/countries';

import { useUser } from 'web/contexts/UserContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';
import { useConfirmDialog } from 'components/contexts/ConfirmContext';

import { StickyAction } from 'web/components/Layout';

import DeleteButton from 'components/buttons/DeleteButton';
import ErrorAlert from 'components/ErrorAlert';

import ProfileImage from 'web/components/ProfileImage';
import IdentityVerification from 'web/components/IdentityVerification';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import SelectField from 'components/form/SelectField';
import SwitchField from 'components/form/SwitchField';
import DynamicPEPFields from 'components/form/DynamicPEPFields';
import AutocompleteField from 'components/form/AutocompleteField';
import SubmitButton from 'components/form/SubmitButton';

import { PAGINATE_AUDITING_COMPANIES } from 'api/queries/auditingCompanyQueries';
import { DELETE_ONE_USER } from 'api/mutations/userMutations';

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-end;

	& > *:not(:last-child) {
		margin-right: 50px;
		flex: 1 1 auto;
	}

	& > *:last-child {
		width: 75%;
		flex: 1 1 75%;
	}
`;

const FormGrid = styled.div`
	@media (min-width: 1500px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 32px;
		margin-bottom: 32px;
	}
`;

const FormGridCol = styled.div``;

export default function EditUserForm({
	user,
	updateOneUser,
	userLoading,
	updateOneUserLoading,
	userRefetch,
	userId,
}) {
	const { user: currentUser } = useUser();
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const [isPEP, setIsPEP] = useState(false);
	const [isUserAuditor, setIsUserAuditor] = useState(false);

	const isCurrentUser = currentUser?._id === user?._id;
	const lastVerifiedEmail = isCurrentUser ? user?.email : null;
	const lastVerifiedPhone = isCurrentUser ? user?.phone : null;

	const {
		data: { auditingCompanies } = { auditingCompanies: [] },
		loading: auditingCompaniesLoading,
		error: auditingCompaniesError,
	} = useQuery(PAGINATE_AUDITING_COMPANIES);

	const [
		deleteOneUser,
		{ loading: deleteOneUserLoading, error: deleteOneUserError },
	] = useMutation(DELETE_ONE_USER);

	useEffect(() => {
		if (!user?.role || userLoading) return;

		setIsPEP(user.isPoliticallyExposedPerson);
		setIsUserAuditor(user.role === 'auditor');
	}, [user?.role, user?.isPoliticallyExposedPerson]);

	const onSubmit = async values => {
		try {
			const verifyFields = [];
			if (lastVerifiedEmail && values.email !== lastVerifiedEmail) {
				verifyFields.push('e-post');
			}
			if (lastVerifiedPhone && values.phone !== lastVerifiedPhone) {
				verifyFields.push('telefon');
			}

			if (verifyFields.length > 0) {
				const verified = await verify({
					title: 'Bekreft endringer',
					text: `Du har endret felt som trenger verifisering: ${verifyFields.join(
						', '
					)}. Er du sikker på at du vil lagre og gjennomføre verifiseringen?`,
				});

				if (!verified) {
					notify('Endringer ble ikke lagret.', 'warning');
					return;
				}
			}

			if (values.role !== 'auditor') {
				delete values.auditingCompanyId;
			}

			values.address = {
				street_address: values.street_address || undefined,
				postal_code: values.postal_code || undefined,
				locality: values.locality || undefined,
				country: values.country || undefined,
			};

			values.isPoliticallyExposedPerson =
				values.isPoliticallyExposedPerson ?? false;

			values.userPoliticallyExposedPersonRoles = Array.isArray(
				values.userPoliticallyExposedPersonRoles
			)
				? values.userPoliticallyExposedPersonRoles
				: undefined;

			if (!values.isPoliticallyExposedPerson) {
				values.userPoliticallyExposedPersonRoles = [];
			}

			if (
				values.isPoliticallyExposedPerson &&
				values.userPoliticallyExposedPersonRoles?.length === 0
			) {
				notify(
					'Vennligst legg til roller for politisk eksponert person',
					'warning'
				);
				return;
			}

			delete values.userRoles;

			const { data } = await updateOneUser({
				variables: {
					_id: userId,
					...values,
				},
			});

			if (data.user) {
				notify('Brukeren ble oppdatert!');
			}
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<>
			<ErrorAlert error={deleteOneUserError} />
			{!auditingCompaniesLoading && isUserAuditor && (
				<ErrorAlert error={auditingCompaniesError} />
			)}

			<Form
				values={{ ...user, ...user.address }}
				key={user._id}
				isLoading={
					userLoading ||
					updateOneUserLoading ||
					auditingCompaniesLoading
				}
				onSubmit={onSubmit}
			>
				<FormGrid>
					<FormGridCol>
						<SelectField
							label="Brukertype"
							name="role"
							options={roles.map(r => ({
								value: r._id,
								label: r.name,
							}))}
							onChange={option => {
								setIsUserAuditor(option.value === 'auditor');
							}}
							disabled={user.role === 'silent'}
						/>

						<TextField
							name="name"
							type="text"
							label="Navn"
							required
						/>

						<TextField
							name="position"
							type="text"
							label="Stilling"
						/>

						<TextField name="email" type="email" label="E-post" />

						<TextField name="phone" type="tel" label="Telefon" />

						{!auditingCompaniesLoading && isUserAuditor && (
							<AutocompleteField
								label="Revisorfirma"
								name="auditingCompanyId"
								options={auditingCompanies.items.map(
									auditingCompany => ({
										value: auditingCompany._id,
										label: auditingCompany.name,
									})
								)}
							/>
						)}

						<TextField
							name="socialno"
							type="number"
							min="10000000000"
							max="31129999999"
							label="Personnummer"
						/>

						<TextField
							name="dNumber"
							type="number"
							min="10000000000"
							max="31129999999"
							label="D-nummer"
						/>
					</FormGridCol>

					<FormGridCol>
						<TextField name="street_address" label="Gateadresse" />

						<Row>
							<TextField
								name="postal_code"
								label="Postnummer"
								fullWidth
							/>

							<TextField name="locality" label="Sted" fullWidth />
						</Row>

						<AutocompleteField
							label="Bosatt i land"
							name="country"
							options={countries.map(country => ({
								label: country,
								value: country,
							}))}
						/>

						<AutocompleteField
							label="Statsborger i land"
							name="citizenship"
							options={countries.map(country => ({
								label: country,
								value: country,
							}))}
						/>

						<SwitchField
							name="isPoliticallyExposedPerson"
							checked={isPEP}
							label={`Ja, ${user.name} er en politisk eksponert person`}
							onChange={e => {
								setIsPEP(e);
							}}
						/>

						{isPEP && (
							<DynamicPEPFields
								name="userPoliticallyExposedPersonRoles"
								existingRelations={
									user.userPoliticallyExposedPersonRoles || []
								}
							/>
						)}

						{user._id && <ProfileImage userId={user._id} />}

						<IdentityVerification
							userId={user._id}
							fileName={user.identificationFile}
							onCompleted={userRefetch}
						/>
					</FormGridCol>
				</FormGrid>
				<StickyAction>
					{isCurrentUser && currentUser?.role === 'admin' && (
						<DeleteButton
							disabled={deleteOneUserLoading}
							onClick={async () => {
								const { data } = await deleteOneUser({
									variables: { _id: user._id },
								});

								if (!data.deleted) {
									throw new Error(
										'Det oppstod en feil ved sletting!'
									);
								}
							}}
							redirect="/brukere/"
						/>
					)}

					<SubmitButton
						variant="contained"
						size="large"
						icon={<SaveIcon />}
					>
						Oppdater bruker
					</SubmitButton>
				</StickyAction>
			</Form>
		</>
	);
}
