import styled from 'styled-components';
import { Link } from 'react-router';
import StatusIcon from 'components/StatusIcon';

const StyledLink = styled(Link)`
	line-height: 0;
`;

export default function DeviationStatus({ id, hasDeviations }) {
	return (
		<StyledLink to={`/bedrifter/${id}/rediger/`}>
			<StatusIcon
				error={hasDeviations}
				errorTitle="Bedriften har avvik!"
				successTitle="Bedriften har ingen avvik!"
			/>
		</StyledLink>
	);
}
