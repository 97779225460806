import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router';

import NotFound from 'web/screens/NotFound';
import ErrorAlert from 'components/ErrorAlert';

import DesktopLayout, { Content } from 'web/components/Layout';

import EditUserForm from './components/EditUserForm';
import UserTenantRoles from './components/UserTenantRoles';
import Settings from './components/Settings';

import { FIND_USER_WITH_USER_ROLES } from 'api/queries/userQueries';
import { UPDATE_ONE_USER } from 'api/mutations/userMutations';

export default function UsersEdit() {
	const { userId } = useParams();

	const {
		data: { user } = { user: {} },
		loading: userLoading,
		error: userError,
		refetch: userRefetch,
	} = useQuery(FIND_USER_WITH_USER_ROLES, {
		variables: {
			_id: userId,
		},
	});

	const [
		updateOneUser,
		{ loading: updateOneUserLoading, error: updateOneUserError },
	] = useMutation(UPDATE_ONE_USER, {
		refetchQueries: ['findOneUser'],
		fetchPolicy: 'no-cache',
	});

	if (!user) {
		return (
			<NotFound
				label="Bruker ikke funnet"
				title="Denne brukeren ble ikke funnet!"
			/>
		);
	}

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Brukere',
					to: '/brukere/',
				},
				{
					label: user.name || 'Laster…',
				},
			]}
			title={user?.name ? `Bruker: ${user.name}` : 'Bruker'}
		>
			<Content>
				<ErrorAlert error={userError} />
				<ErrorAlert error={updateOneUserError} />

				<EditUserForm
					user={user}
					updateOneUser={updateOneUser}
					userLoading={userLoading}
					updateOneUserLoading={updateOneUserLoading}
					userRefetch={userRefetch}
					userId={userId}
				/>

				<UserTenantRoles
					user={user}
					updateOneUser={updateOneUser}
					updateOneUserLoading={updateOneUserLoading}
				/>

				<Settings user={user} />
			</Content>
		</DesktopLayout>
	);
}
