import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import {
	Dialog,
	DialogTitle,
	DialogCloseButton,
	DialogActions,
	DialogContent,
	Button,
} from 'components/dialogs/DialogBox';
import ErrorMessage from 'components/ErrorMessage';
import { CompanyInfoContent } from 'web/components/CompanyInfo';

import { GET_ONE_COMPANY_WITH_BASIC_INFO } from 'api/queries/tenantQueries';

const StyledDialogContent = styled(DialogContent)`
	min-height: 500px;
`;

export default function CompanyBasicInfoDialog({ open, companyId, onClose }) {
	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
			<DialogCloseButton onClick={onClose} />
			<CompanyContent companyId={companyId} />

			<DialogActions>
				<Button onClick={onClose}>Lukk</Button>
			</DialogActions>
		</Dialog>
	);
}

function CompanyContent({ companyId }) {
	const {
		data: { company } = { company: null },
		error,
		loading,
	} = useQuery(GET_ONE_COMPANY_WITH_BASIC_INFO, {
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'all',
		variables: {
			_id: companyId,
		},
		skip: !companyId,
	});

	if (loading) {
		return <LoadingContent />;
	}

	if (error || !company) {
		return (
			<ErrorContent
				error={error || 'Kunne ikke hente informasjon om bedrift'}
			/>
		);
	}

	const { name } = company;

	return (
		<>
			<DialogTitle>{name}</DialogTitle>

			<StyledDialogContent>
				<CompanyInfoContent company={company} />
			</StyledDialogContent>
		</>
	);
}

function LoadingContent() {
	return (
		<>
			<DialogTitle>Laster bedrift</DialogTitle>
			<StyledDialogContent>
				<p>Vennligst vent...</p>
			</StyledDialogContent>
		</>
	);
}

function ErrorContent({ error }) {
	return (
		<>
			<DialogTitle>Bedrift kunne ikke lastes</DialogTitle>
			<StyledDialogContent>
				<ErrorMessage errors={[error]} />
			</StyledDialogContent>
		</>
	);
}
