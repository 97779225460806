import styled from 'styled-components';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useCalendarTimeline } from '../context/CalendarTimelineContext';

const now = new Date();
const currentYear = now.getFullYear();
const currentMonth = now.getMonth();
const monthsInYear = 12;

const columnWidth = 80;
const currenMonthScrollPosition =
	(monthsInYear + currentMonth - 3) * columnWidth; // 3 month before current month

const TimelineYearSelectWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const SelectedYearButton = styled(Button)`
	color: ${p => p.theme.palette.text.primary};
	font-weight: bold;
	font-size: 18px;
	letter-spacing: 1px;
	min-width: auto;
	padding: 0;

	:disabled {
		color: ${p => p.theme.palette.text.primary};
	}
`;

export default function TimelineYearSelect() {
	const { selectedYear, setSelectedYear, setScrollPosition } =
		useCalendarTimeline();

	return (
		<TimelineYearSelectWrapper>
			<IconButton
				type="button"
				aria-label="Forrige år"
				title="Forrige år"
				size="small"
				onClick={() => {
					setSelectedYear(selectedYear - 1);
					setScrollPosition({
						x: 0,
						y: 0,
					});
				}}
			>
				<ChevronLeftIcon fontSize="inherit" />
			</IconButton>

			<SelectedYearButton
				title="Gå til gjeldende måned"
				size="small"
				onClick={() => {
					setSelectedYear(currentYear);
					setScrollPosition({
						x: currenMonthScrollPosition,
						y: 0,
					});
				}}
			>
				{selectedYear - 1} – {selectedYear + 1}
			</SelectedYearButton>

			<IconButton
				aria-label="Neste år"
				title="Neste år"
				size="small"
				disabled={selectedYear >= currentYear}
				onClick={() => {
					setSelectedYear(selectedYear + 1);
					setScrollPosition({
						x: 0,
						y: 0,
					});
				}}
			>
				<ChevronRightIcon fontSize="inherit" />
			</IconButton>
		</TimelineYearSelectWrapper>
	);
}
