import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import Markdown from 'react-markdown';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import ArticleIcon from '@mui/icons-material/Article';
import CommentIcon from '@mui/icons-material/Comment';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import MuiFormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import {
	taskEntryStatuses,
	taskEntryPostponedStatuses,
} from 'server/templates/assignments';

import { formatDate } from 'utils/dates';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';
import { useUser } from 'web/contexts/UserContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import FileUploadDialog from 'web/screens/dialogs/files/FileUploadDialog';

import ErrorMessage from 'components/ErrorMessage';

import Form, {
	FormGroup,
	Select,
	DatePicker,
	MarkdownField,
} from 'web/form/Form';
import { StyledALink } from 'web/components/Link';

import {
	FIND_ONE_COMPANY_ASSIGNMENT_TASK,
	GET_TASK_DEADLINE_ENTRIES,
} from 'api/queries/companyAssignmentTaskQueries';
import {
	UPDATE_ONE_COMPANY_ASSIGNMENT_TASK,
	UPDATE_ONE_TASK_DEADLINE_ENTRY,
	DELETE_ONE_TASK_DEADLINE_ENTRY,
	TOGGLE_TASK_DEADLINE_ENTRY_SUB_TASK,
} from 'api/mutations/companyAssignmentTaskMutations';

import { GET_FILES } from 'api/queries/fileQueries';
import { DELETE_ONE_FILE } from 'api/mutations/fileMutations';

import { PAGINATE_COMMENTS } from 'api/queries/commentQueries';
import {
	INSERT_ONE_COMMENT,
	DELETE_ONE_COMMENT,
} from 'api/mutations/commentMutations';

export default function EditAssignmentEntryDialog({
	deadlineEntry,
	open,
	onClose,
}) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="md"
			scroll="body"
			PaperProps={{
				sx: {
					minHeight: 400,
				},
			}}
		>
			{deadlineEntry && (
				<AssignmentEntry
					deadlineEntry={deadlineEntry}
					onClose={onClose}
				/>
			)}
		</Dialog>
	);
}

//TODO: Rename to DeadlineEntry?
// TODO: Do we ned Task?
// TODO: Get one single deadlineEntry? ANd include task in that query?
function AssignmentEntry({ deadlineEntry, onClose }) {
	const { verify } = useConfirmDialog();
	const { notify } = useSnackbar();
	const [tabIndex, setTabIndex] = useState(0);

	const {
		data: { task } = { task: null },
		loading: taskLoading,
		error: taskError,
	} = useQuery(FIND_ONE_COMPANY_ASSIGNMENT_TASK, {
		variables: {
			_id: deadlineEntry.taskId,
		},
		skip: !deadlineEntry.taskId,
	});

	const { data: { files = [] } = { files: [] }, error: filesError } =
		useQuery(GET_FILES, { variables: { docId: deadlineEntry._id } });

	const {
		data: { comments } = { comments: { count: 0, items: [] } },
		error: commentsError,
	} = useQuery(PAGINATE_COMMENTS, {
		variables: {
			docId: deadlineEntry._id,
			orderBy: 'createdAt',
			order: 1,
		},
	});

	const [
		deleteDeadlineEntry,
		{
			loading: deleteDeadlineEntryLoading,
			error: deleteDeadlineEntryError,
		},
	] = useMutation(DELETE_ONE_TASK_DEADLINE_ENTRY, {
		refetchQueries: [
			{
				query: GET_TASK_DEADLINE_ENTRIES,
				variables: {
					taskId: deadlineEntry.taskId,
					isOverdue: true, // Refetch with isOverdue set to true
				},
			},
			{
				query: GET_TASK_DEADLINE_ENTRIES,
				variables: {
					taskId: deadlineEntry.taskId,
					isOverdue: false, // Refetch with isOverdue set to false
				},
			},
		],
	});

	if (taskLoading) {
		return null;
	}

	return (
		<div>
			<Title
				onClose={onClose}
				title={task.name}
				subtitle={task.frequencyLabel}
			/>

			<DialogContent dividers>
				<ErrorMessage
					errors={[
						taskError,
						filesError,
						commentsError,
						deleteDeadlineEntryError,
					]}
				/>

				<Box
					sx={{
						flexGrow: 1,
						display: 'flex',
					}}
				>
					<VerticalTabs
						value={tabIndex}
						onChange={newTabIndex => setTabIndex(newTabIndex)}
					>
						<VerticalTab label="Info" index={0} />

						<VerticalTab
							label="Vedlegg"
							count={files?.length}
							index={1}
						/>

						<VerticalTab
							label="Kommentarer"
							count={comments?.count}
							index={2}
						/>

						<VerticalTab label="Rutiner" index={3} />

						<VerticalTab label="Historikk" index={4} />
					</VerticalTabs>

					<TabPanel value={tabIndex} index={0}>
						<PanelSection>
							<DeadlineEntryStatus
								taskId={task._id}
								date={deadlineEntry.date}
								entryId={deadlineEntry._id}
								entryType={deadlineEntry.type}
								entryStatus={deadlineEntry.status}
							/>

							<PostponedStatus
								taskId={task._id}
								date={deadlineEntry.date}
								entryId={deadlineEntry._id}
								entryType={deadlineEntry.type}
								postponedStatus={deadlineEntry?.postponedStatus}
							/>
						</PanelSection>

						<DeadlineEntryDate
							taskId={task._id}
							entryId={deadlineEntry._id}
							date={deadlineEntry.date}
						/>

						<NotificationSettings
							taskId={task._id}
							entryId={deadlineEntry._id}
							notifications={deadlineEntry.notifications}
						/>

						<SubTasks
							taskId={task._id}
							date={deadlineEntry.date}
							deadlineEntryId={deadlineEntry._id}
							subTasks={deadlineEntry?.subTasks}
						/>
					</TabPanel>

					<TabPanel value={tabIndex} index={1}>
						<EntryFiles
							deadlineEntryId={deadlineEntry._id}
							files={files}
						/>
					</TabPanel>

					<TabPanel value={tabIndex} index={2}>
						<EntryComments
							deadlineEntryId={deadlineEntry._id}
							comments={comments.items}
						/>
					</TabPanel>

					<TabPanel value={tabIndex} index={3}>
						<TaskRoutines
							taskId={task._id}
							globalRoutine={task?.globalRoutine}
							routine={task?.routine}
						/>
					</TabPanel>

					<TabPanel value={tabIndex} index={4}>
						<AssignmentEntryHistory
							history={deadlineEntry?.history}
						/>
					</TabPanel>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button
					disabled={deleteDeadlineEntryLoading}
					onClick={async () => {
						try {
							if (
								!(await verify({
									title: 'Slett tidsfrist?',
									text: 'Er du sikker på at du vil slette tidsfristen?',
								}))
							) {
								return;
							}

							const { data } = await deleteDeadlineEntry({
								variables: { _id: deadlineEntry._id },
							});

							if (data?.deleted) {
								notify('Tidsfristen ble slettet!');

								onClose();
							}
						} catch (err) {
							console.error(err);
						}
					}}
				>
					Slett
				</Button>

				<Button onClick={onClose} variant="contained" autoFocus>
					Lukk
				</Button>
			</DialogActions>
		</div>
	);
}

const StatusSelectWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

function DeadlineEntryStatus({ taskId, entryId, entryType, entryStatus }) {
	const { verify } = useConfirmDialog();
	const { notify } = useSnackbar();

	const [updateDeadlineEntry, { loading, error }] = useMutation(
		UPDATE_ONE_TASK_DEADLINE_ENTRY,
		{
			refetchQueries: [
				{
					query: GET_TASK_DEADLINE_ENTRIES,
					variables: {
						taskId,
						isOverdue: true, // Refetch with isOverdue set to true
					},
				},
				{
					query: GET_TASK_DEADLINE_ENTRIES,
					variables: {
						taskId,
						isOverdue: false, // Refetch with isOverdue set to false
					},
				},
				'myCompaniesWitDueTaskDeadlineEntries',
			],
		}
	);

	const statuses = taskEntryStatuses[entryType] ?? null;

	if (!statuses) {
		return <ErrorMessage errors={['Mangler statuser på oppgave']} />;
	}

	const handleStatusChange = async (newStatus, isActive = false) => {
		if (isActive) {
			notify('Status er allerede aktiv');

			return;
		}

		try {
			const { data } = await updateDeadlineEntry({
				variables: {
					_id: entryId,
					input: {
						status: newStatus,
					},
				},
			});

			if (data?.deadlineEntry?._id) {
				notify(`Status ble oppdatert`);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const handleStatusReset = async () => {
		if (
			!(await verify({
				title: 'Nullstill status',
				text: 'Er du sikker på at du vil fjerne statusen som er satt?',
			}))
		) {
			return;
		}

		await handleStatusChange(null);
	};

	if (statuses.length <= 3) {
		const buttons = statuses.map(status => (
			<Button
				key={status}
				variant={status === entryStatus ? 'contained' : 'outlined'}
				onClick={() =>
					handleStatusChange(status, status === entryStatus)
				}
				disabled={
					loading ||
					(status === 'Kontrollert' &&
						entryStatus !== 'Utført' &&
						entryStatus !== 'Kontrollert')
				}
				fullWidth
			>
				{status}
			</Button>
		));

		return (
			<div>
				<PanelTitle>
					<div>Status</div>

					{entryStatus && (
						<Button
							type="button"
							size="small"
							onClick={handleStatusReset}
						>
							(Nullstill)
						</Button>
					)}
				</PanelTitle>

				<ErrorMessage errors={[error]} />

				<Stack direction="row" spacing={2}>
					{buttons}
				</Stack>
			</div>
		);
	}

	return (
		<div>
			<PanelTitle>
				<div>Status</div>

				{entryStatus && (
					<Button
						type="button"
						onClick={handleStatusReset}
						size="small"
					>
						(Nullstill)
					</Button>
				)}
			</PanelTitle>

			<ErrorMessage errors={[error]} />

			<StatusSelectWrapper>
				<FormControl disabled={loading} sx={{ minWidth: 350 }}>
					<MuiSelect
						id="status-select"
						value={entryStatus ?? ''}
						inputProps={{ 'aria-label': 'Status' }}
						onChange={event =>
							handleStatusChange(event.target.value)
						}
						autoWidth
						displayEmpty
					>
						<MenuItem value="" disabled>
							Velg status
						</MenuItem>

						{statuses.map(status => (
							<MenuItem key={status} value={status}>
								{status}
							</MenuItem>
						))}
					</MuiSelect>
				</FormControl>
			</StatusSelectWrapper>
		</div>
	);
}

function PostponedStatus({
	taskId,
	date,
	entryId,
	entryType,
	postponedStatus,
}) {
	const [showPostponedForm, setShowPostponedForm] = useState(false);

	const statuses = taskEntryPostponedStatuses[entryType] ?? null;

	if (!statuses) {
		return null;
	}

	return (
		<div>
			{!postponedStatus && (
				<Button
					onClick={() => setShowPostponedForm(!showPostponedForm)}
				>
					Søk om utsetting
				</Button>
			)}

			{postponedStatus && (
				<PostponedStatusMessage
					taskId={taskId}
					date={date}
					entryId={entryId}
					postponedStatus={postponedStatus}
				/>
			)}

			{showPostponedForm && (
				<PostponedStatusForm
					taskId={taskId}
					date={date}
					entryId={entryId}
					postponedStatus={postponedStatus}
					statuses={statuses}
					onAbort={() => setShowPostponedForm(false)}
					afterSubmit={() => setShowPostponedForm(false)}
				/>
			)}
		</div>
	);
}

const PostponedStatusAlert = styled(Alert)`
	margin-top: 30px;
`;

function PostponedStatusMessage({ taskId, entryId, postponedStatus }) {
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const [updateDeadlineEntry, { loading, error }] = useMutation(
		UPDATE_ONE_TASK_DEADLINE_ENTRY,
		{
			refetchQueries: [
				{
					query: GET_TASK_DEADLINE_ENTRIES,
					variables: {
						taskId,
					},
				},
			],
		}
	);

	return (
		<PostponedStatusAlert
			severity="warning"
			action={
				<IconButton
					title="Fjern utsatt status"
					disabled={loading}
					onClick={async () => {
						try {
							if (
								!(await verify({
									title: 'Fjern utsatt tidsfrist',
									text: 'Er du sikker på at du vil fjerne den utsatte tidsfristen?',
								}))
							) {
								return;
							}

							const { data } = await updateDeadlineEntry({
								variables: {
									_id: entryId,
									input: {
										postponedStatus: null,
									},
								},
							});

							if (data?.deadlineEntry?._id) {
								notify(`Utsatt status ble fjernet`);
							}
						} catch (err) {
							console.error(err);
						}
					}}
				>
					<DeleteIcon />
				</IconButton>
			}
		>
			<AlertTitle>Tidsfrist utsatt!</AlertTitle>

			<ErrorMessage errors={[error]} />

			<div>
				<strong>Hvem søker:</strong> {postponedStatus.status}
			</div>
		</PostponedStatusAlert>
	);
}

const PostponedStatusFormWrapper = styled.div`
	background-color: ${({ theme }) => theme.palette.background.gray};
	padding: 40px;
`;

function PostponedStatusForm({
	taskId,
	date,
	entryId,
	postponedStatus,
	statuses,
	onAbort,
	afterSubmit,
}) {
	const { notify } = useSnackbar();

	const [updateDeadlineEntry, { loading, error }] = useMutation(
		UPDATE_ONE_TASK_DEADLINE_ENTRY,
		{
			refetchQueries: [
				{
					query: GET_TASK_DEADLINE_ENTRIES,
					variables: {
						taskId,
					},
				},
			],
		}
	);

	return (
		<PostponedStatusFormWrapper>
			<Form
				values={{
					newStatus: postponedStatus?.status ?? statuses[0],
					newDate: date,
				}}
				onSubmit={async ({ newStatus, newDate }) => {
					try {
						const { data } = await updateDeadlineEntry({
							variables: {
								_id: entryId,
								input: {
									postponedStatus: {
										status: newStatus,
									},
									date: newDate,
								},
							},
						});

						if (data?.deadlineEntry?._id) {
							notify(`Utsatt status ble oppdatert`);
						}

						afterSubmit();
					} catch (err) {
						console.error(err);
					}
				}}
			>
				<ErrorMessage errors={[error]} />

				<FormGroup>
					<Select
						name="newStatus"
						label="Hvem søker?"
						options={statuses.map(status => ({
							value: status,
							label: status,
						}))}
						fullWidth
						required
					/>
				</FormGroup>

				<FormGroup>
					<DatePicker name="newDate" label="Ny dato" required />
				</FormGroup>

				<Button onClick={onAbort}>Avbryt</Button>

				<Button type="submit" variant="contained" disabled={loading}>
					Utsett
				</Button>
			</Form>
		</PostponedStatusFormWrapper>
	);
}

const DeadlineEntryDateDisplay = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

function DeadlineEntryDate({ taskId, entryId, date }) {
	const { notify } = useSnackbar();
	const [editMode, setEditMode] = useState(false);

	const [updateDeadlineEntry, { loading, error }] = useMutation(
		UPDATE_ONE_TASK_DEADLINE_ENTRY,
		{
			refetchQueries: [
				{
					query: GET_TASK_DEADLINE_ENTRIES,
					variables: {
						taskId,
					},
				},
			],
		}
	);

	return (
		<PanelSection>
			<PanelTitle>Frist</PanelTitle>

			{editMode ? (
				<Form
					values={{
						newDate: date,
					}}
					onSubmit={async ({ newDate }) => {
						try {
							const { data } = await updateDeadlineEntry({
								variables: {
									_id: entryId,
									input: {
										date: newDate,
									},
								},
							});

							if (data?.deadlineEntry?._id) {
								notify(`Dato ble oppdatert`);
							}

							setEditMode(false);
						} catch (err) {
							console.error(err);
						}
					}}
				>
					<ErrorMessage errors={[error]} />

					<FormGroup>
						<DatePicker name="newDate" label="Ny dato" required />
					</FormGroup>

					<Button
						onClick={() => {
							setEditMode(false);
						}}
						size="small"
					>
						Avbryt
					</Button>

					<Button
						type="submit"
						variant="contained"
						size="small"
						disabled={loading}
					>
						Lagre
					</Button>
				</Form>
			) : (
				<DeadlineEntryDateDisplay>
					<div>{formatDate(date, 'DD.MM.YYYY')}</div>

					<IconButton
						title="Endre frist"
						size="small"
						onClick={() => setEditMode(true)}
					>
						<EditIcon fontSize="small" />
					</IconButton>
				</DeadlineEntryDateDisplay>
			)}
		</PanelSection>
	);
}

function NotificationSettings({ taskId, entryId, notifications }) {
	const { notify } = useSnackbar();

	const [updateDeadlineEntry, { loading, error }] = useMutation(
		UPDATE_ONE_TASK_DEADLINE_ENTRY,
		{
			refetchQueries: [
				{
					query: GET_TASK_DEADLINE_ENTRIES,
					variables: {
						taskId,
					},
				},
			],
		}
	);

	return (
		<PanelSection>
			<PanelTitle>Varslinger</PanelTitle>

			<ErrorMessage errors={[error]} />

			<MuiFormGroup>
				<FormControlLabel
					control={
						<Switch
							checked={notifications}
							disabled={loading}
							onChange={async () => {
								try {
									const { data } = await updateDeadlineEntry({
										variables: {
											_id: entryId,
											input: {
												notifications: !notifications,
											},
										},
									});

									if (data?.deadlineEntry?._id) {
										notify(`Notifikasjoner ble oppdatert!`);
									}
								} catch (err) {
									console.error(err);
								}
							}}
						/>
					}
					label="Send en påminnelse på e-post til regnskapsfører og kundeansvarlig ved utløp av tidsfrist."
				/>
			</MuiFormGroup>
		</PanelSection>
	);
}

const SubTasksList = styled.div`
	margin: 0 0 0 20px;
`;

const SubTaskLabelByline = styled.div`
	color: ${p => p.theme.palette.text.secondary};
	font-size: 12px;
	font-type: italic;
	line-height: 1;
`;

function SubTasks({ taskId, deadlineEntryId, subTasks }) {
	const { notify } = useSnackbar();

	if (!subTasks || subTasks.length === 0) {
		return null;
	}

	const [toggleSubTask, { error }] = useMutation(
		TOGGLE_TASK_DEADLINE_ENTRY_SUB_TASK,
		{
			refetchQueries: [
				{
					query: GET_TASK_DEADLINE_ENTRIES,
					variables: {
						taskId,
					},
				},
			],
		}
	);

	return (
		<PanelSection>
			<PanelTitle>Deloppgaver</PanelTitle>

			<ErrorMessage errors={[error]} />

			{subTasks.length > 0 && (
				<SubTasksList>
					{subTasks.map((subTask, index) => (
						<MuiFormGroup key={index}>
							<FormControlLabel
								control={
									<Checkbox
										checked={subTask.completed}
										onChange={async () => {
											try {
												const { data } =
													await toggleSubTask({
														variables: {
															_id: deadlineEntryId,
															index,
														},
													});

												if (data.toggled) {
													notify(
														'Deloppgaven ble oppdatert!'
													);
												}
											} catch (err) {
												console.error(err);
											}
										}}
									/>
								}
								label={
									<div>
										<div>{subTask.name}</div>

										{subTask.completed && (
											<SubTaskLabelByline>
												{subTask.updatedAt &&
													formatDate(
														subTask.updatedAt,
														'DD.MM.YYYY kl. HH:mm'
													)}

												{subTask.updatedByDisplayName &&
													` av ${subTask.updatedByDisplayName}`}
											</SubTaskLabelByline>
										)}
									</div>
								}
							/>
						</MuiFormGroup>
					))}
				</SubTasksList>
			)}
		</PanelSection>
	);
}

const EntryFilesHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	margin-bottom: 20px;
`;

const EntryFilesTitle = styled.h3`
	margin: 0;
`;

const NotFoundMessage = styled.div`
	font-style: italic;
	margin: 40px 0;
	text-align: center;
`;

function EntryFiles({ deadlineEntryId, files }) {
	const { verify } = useConfirmDialog();
	const [showDialog, setShowDialog] = useState(false);

	const [removeFile, { loading: removeFileLoading, error: removeFileError }] =
		useMutation(DELETE_ONE_FILE, {
			refetchQueries: [
				{
					query: GET_FILES,
					variables: {
						docId: deadlineEntryId,
					},
				},
			],
		});

	return (
		<div>
			<EntryFilesHeader>
				<EntryFilesTitle>Vedlegg</EntryFilesTitle>

				<Button
					size="small"
					variant="contained"
					color="primary"
					type="button"
					startIcon={<SaveAltIcon />}
					onClick={() => {
						setShowDialog(true);
					}}
				>
					Legg til dokument
				</Button>
			</EntryFilesHeader>

			<ErrorMessage errors={[removeFileError]} />

			{files?.length > 0 ? (
				<List dense={true}>
					{files.map(file => (
						<ListItem
							key={file._id}
							secondaryAction={
								<IconButton
									edge="end"
									aria-label="Slett dokument"
									title="Slett dokument"
									disabled={removeFileLoading}
									onClick={async () => {
										try {
											if (
												!(await verify({
													title: `Slett dokument`,
													text: `Er du sikker på at du vil slette ${file.fileName}?`,
												}))
											) {
												return;
											}

											await removeFile({
												variables: {
													_id: file._id,
												},
											});
										} catch (err) {
											console.error(err);
										}
									}}
								>
									<DeleteIcon />
								</IconButton>
							}
						>
							<ListItemAvatar>
								<Avatar>
									<ArticleIcon />
								</Avatar>
							</ListItemAvatar>

							<ListItemText
								primary={
									<StyledALink
										href={file.url}
										title={`Last ned ${file.fileName}`}
										target="_blank"
										rel="noreferrer"
									>
										{file.fileName}
									</StyledALink>
								}
								secondary={`Lastet opp av ${
									file.createdByDisplayName
								} ${formatDate(
									file.createdAt,
									'DD.MM.YYYY kl. HH:mm'
								)}`}
							/>
						</ListItem>
					))}
				</List>
			) : (
				<NotFoundMessage>
					Det er ikke lastet opp noen dokumenter
				</NotFoundMessage>
			)}

			<FileUploadDialog
				docId={deadlineEntryId}
				uploadContext="deadlineEntry"
				open={showDialog}
				onClose={() => setShowDialog(false)}
			/>
		</div>
	);
}

const CommentFormActions = styled.div`
	margin-top: 15px;
	display: flex;
	justify-content: flex-end;
`;

function EntryComments({ deadlineEntryId, comments }) {
	const { user } = useUser();
	const { verify } = useConfirmDialog();

	const [addComment, { loading: addCommentLoading, error: addCommentError }] =
		useMutation(INSERT_ONE_COMMENT, {
			refetchQueries: ['paginateComments'],
		});

	const [
		removeComment,
		{ loading: removeCommentLoading, error: removeCommentError },
	] = useMutation(DELETE_ONE_COMMENT, {
		refetchQueries: ['paginateComments'],
	});

	return (
		<PanelSection>
			<PanelTitle>Kommentarer</PanelTitle>

			<ErrorMessage errors={[addCommentError, removeCommentError]} />

			{comments?.length > 0 && (
				<List dense={true}>
					{comments.map(comment => (
						<ListItem
							key={comment._id}
							secondaryAction={
								comment.createdBy === user._id && (
									<IconButton
										edge="end"
										aria-label="Slett kommentar"
										title="Slett kommentar"
										disabled={removeCommentLoading}
										onClick={async () => {
											try {
												if (
													!(await verify({
														title: `Slett kommentar`,
														text: `Er du sikker på at du vil slette kommentaren?`,
													}))
												) {
													return;
												}

												await removeComment({
													variables: {
														_id: comment._id,
													},
												});
											} catch (err) {
												console.error(err);
											}
										}}
									>
										<DeleteIcon />
									</IconButton>
								)
							}
						>
							<ListItemAvatar>
								<Avatar>
									<CommentIcon />
								</Avatar>
							</ListItemAvatar>

							<ListItemText
								primary={comment.message}
								secondary={`Skrevet av ${
									comment.createdByDisplayName
								} ${formatDate(
									comment.createdAt,
									'DD.MM.YYYY kl. HH:mm'
								)}`}
							/>
						</ListItem>
					))}
				</List>
			)}

			<form
				onSubmit={async e => {
					try {
						e.preventDefault();
						const message = e.target.message.value.trim();

						const { data } = await addComment({
							variables: {
								docId: deadlineEntryId,
								message,
							},
						});

						if (data?.comment?._id) {
							e.target.reset();
						}
					} catch (err) {
						console.error(err);
					}
				}}
			>
				<p>Legg igjen en kommentar</p>

				<TextField name="message" rows={4} multiline fullWidth />

				<CommentFormActions>
					<Button
						type="submit"
						variant="contained"
						size="small"
						disabled={addCommentLoading}
					>
						Legg inn
					</Button>
				</CommentFormActions>
			</form>
		</PanelSection>
	);
}

const TaskRoutinesTitle = styled.h4`
	line-height: 1;
	margin: 0 0 15px 0;
`;

function TaskRoutines({ taskId, globalRoutine, routine }) {
	const [updateTask, { loading, error }] = useMutation(
		UPDATE_ONE_COMPANY_ASSIGNMENT_TASK,
		{
			refetchQueries: [
				{
					query: FIND_ONE_COMPANY_ASSIGNMENT_TASK,
					variables: {
						_id: taskId,
					},
				},
				{
					query: GET_TASK_DEADLINE_ENTRIES,
					variables: {
						taskId,
					},
				},
			],
		}
	);

	return (
		<PanelSection>
			<PanelTitle>Rutiner</PanelTitle>

			{globalRoutine && globalRoutine !== '' && (
				<div>
					<TaskRoutinesTitle>Arbeidsrutiner</TaskRoutinesTitle>

					<MarkdownText>
						<Markdown>{globalRoutine}</Markdown>
					</MarkdownText>
				</div>
			)}

			<ErrorMessage errors={[error]} />

			<EditMarkdownField
				title="Oppgaverutiner"
				content={routine}
				loading={loading}
				onSubmit={async value => {
					try {
						await updateTask({
							variables: {
								_id: taskId,
								input: { routine: value },
							},
						});
					} catch (err) {
						console.error(err);
					}
				}}
			/>
		</PanelSection>
	);
}

function AssignmentEntryHistory({ history }) {
	return (
		<PanelSection>
			<PanelTitle>Historikk</PanelTitle>

			{history && history.length > 0 && (
				<div>
					{history.map(event => (
						<HistoryEvent key={event._id} event={event} />
					))}
				</div>
			)}
		</PanelSection>
	);
}

const HistoryEventWrapper = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.palette.common.gray};
	margin-bottom: 10px;
	padding-bottom: 10px;

	&:last-of-type {
		border-bottom: none;
	}
`;

const HistoryByline = styled.div`
	font-style: italic;
	font-size: 0.8em;
`;

function HistoryEvent({ event }) {
	return (
		<HistoryEventWrapper>
			<div>{event.event}</div>

			<HistoryByline>
				{formatDate(event.createdAt, 'DD.MM.YY kl. HH:mm')} av{' '}
				{event.userDisplayName}
			</HistoryByline>
		</HistoryEventWrapper>
	);
}

const EditMarkdownFieldHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	margin: 0 0 15px 0;
`;

const EditMarkdownFieldLabel = styled.h4`
	margin: 0;
	line-height: 1;
`;

const EditMarkdownFieldContent = styled.div``;

const MarkdownText = styled.div`
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0 0 15px 0;
	}

	h1 {
		font-size: 1.3em;
	}

	h2 {
		font-size: 1.2em;
	}

	h3 {
		font-size: 1.1em;
	}

	p {
		font-size: 1.1em;
		margin: 0 0 30px 0;
		line-height: 1.4;
	}
`;

const EditMarkdownFieldActions = styled.div`
	margin: 15px 0 0 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

function EditMarkdownField({ title, content, loading, onSubmit }) {
	const [editMode, setEditMode] = useState(false);

	return (
		<div>
			<EditMarkdownFieldHeader>
				<EditMarkdownFieldLabel>{title}</EditMarkdownFieldLabel>

				{!editMode && (
					<IconButton
						aria-label="Rediger"
						title="Rediger"
						size="small"
						onClick={() => setEditMode(true)}
					>
						<EditIcon fontSize="small" />
					</IconButton>
				)}
			</EditMarkdownFieldHeader>

			<EditMarkdownFieldContent>
				{editMode ? (
					<Form
						values={{ content }}
						onSubmit={({ content }) => {
							onSubmit(content);
							setEditMode(false);
						}}
					>
						<FormGroup>
							<MarkdownField name="content" />
						</FormGroup>

						<EditMarkdownFieldActions>
							<Button
								onClick={() => {
									setEditMode(false);
								}}
								size="small"
							>
								Avbryt
							</Button>

							<Button
								type="submit"
								variant="contained"
								size="small"
								disabled={loading}
							>
								Lagre
							</Button>
						</EditMarkdownFieldActions>
					</Form>
				) : (
					<div>
						{content && content !== '' ? (
							<MarkdownText>
								<Markdown>{content}</Markdown>
							</MarkdownText>
						) : (
							<Button
								type="button"
								variant="outlined"
								size="small"
								onClick={() => {
									setEditMode(true);
								}}
							>
								Legg til innhold
							</Button>
						)}
					</div>
				)}
			</EditMarkdownFieldContent>
		</div>
	);
}

const PanelSection = styled.div`
	margin-bottom: 30px;
`;

const PanelTitle = styled.h3`
	display: flex;
	align-items: center;
	gap: 5px;
	line-height: 1.6;
	margin: 0 0 20px 0;
`;

const TitleText = styled.span``;

const SubTitlteText = styled.span`
	color: ${({ theme }) => theme.palette.text.secondary};
`;

function Title({ onClose, title, subtitle }) {
	return (
		<DialogTitle x={{ m: 0, p: 2 }}>
			<TitleText>{title}</TitleText>

			{subtitle && <SubTitlteText> – {subtitle}</SubTitlteText>}

			<IconButton
				aria-label="Lukk"
				onClick={onClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
				}}
			>
				<CloseIcon />
			</IconButton>
		</DialogTitle>
	);
}

function VerticalTabs({ tabIndex, onChange, children, ...rest }) {
	return (
		<Tabs
			orientation="vertical"
			value={tabIndex}
			onChange={(_e, newTabIndex) => onChange(newTabIndex)}
			aria-label="Rediger tidsfrist faner"
			sx={{
				borderRight: 1,
				borderColor: 'divider',
				minWidth: 150,
				'&& .MuiTab-root': {
					alignItems: 'baseline',
				},
			}}
			{...rest}
		>
			{children}
		</Tabs>
	);
}

const VerticalTabLabel = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 5px;
	width: 100%;
`;

const VerticalTabLabelText = styled.div`
	line-height: 1;
`;

const VerticalTabLabelBadge = styled.div`
	background-color: ${({ theme }) => theme.palette.primary.main};
	color: ${({ theme }) => theme.palette.text.white};
	border-radius: 50%;
	display: inline-block;
	font-size: 12px;
	height: 20px;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
`;

function VerticalTab({ label, count = 0, index, ...rest }) {
	return (
		<Tab
			{...rest}
			label={
				<VerticalTabLabel>
					<VerticalTabLabelText>{label}</VerticalTabLabelText>

					{count > 0 && (
						<VerticalTabLabelBadge>{count}</VerticalTabLabelBadge>
					)}
				</VerticalTabLabel>
			}
			id={`vertical-tab-${index}`}
			aria-controls={`vertical-tabpanel-${index}`}
		/>
	);
}

const TabPanelWrapper = styled.div`
	padding: 10px 20px;
	width: 100%;
`;

function TabPanel({ children, value, index, ...other }) {
	return (
		<TabPanelWrapper
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</TabPanelWrapper>
	);
}
