import { useMemo, useState } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';

import AddCompanyRoleDialog from './dialogs/AddCompanyRoleDialog';
import { useConfirmDialog } from 'components/contexts/ConfirmContext';
import Icon from 'components/Icon';
import Panel, { EmptyPanel } from 'web/components/panels/Panel';
import { UnderlineLink } from 'web/components/Link';

import { getRoleLabel } from 'server/libs/companyRoles';

export default function CompanyRolesPanel({
	companyId,
	existingUserRoles = [],
	listLabel,
	listOptions,
	onUpdate = () => {},
	enableCreateUser = false,
	loading,
}) {
	const [openDialog, setOpenDialog] = useState(false);

	const users = useMemo(() => {
		return (
			existingUserRoles.map(o => {
				let user = listOptions.find(item => item.value === o.id);
				return { ...user, role: o.role };
			}) || []
		);
	}, [existingUserRoles, listOptions]);

	function handleAddUserRole(newItem) {
		const updatedUserRoles = [...existingUserRoles, newItem];
		onUpdate(updatedUserRoles);
	}

	function handleDeleteUserRole({ id, role }) {
		const updatedUserRoles = existingUserRoles.filter(
			o => !(o.id === id && o.role === role)
		);
		onUpdate(updatedUserRoles);
	}

	function onCreateUser() {
		if (enableCreateUser) {
			return () => setOpenDialog(false);
		} else {
			return null;
		}
	}

	return (
		<Panel
			title="Bedriftsroller"
			action={
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						setOpenDialog(true);
					}}
					endIcon={<AddIcon />}
				>
					Legg til
				</Button>
			}
		>
			<UserList
				users={users}
				handleDeleteUserRole={handleDeleteUserRole}
			/>

			<AddCompanyRoleDialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				onSubmit={handleAddUserRole}
				onCreateUser={onCreateUser()}
				loading={loading}
				list={{
					label: listLabel,
					options: listOptions,
				}}
				companyId={companyId}
			/>
		</Panel>
	);
}

function UserList({ users, handleDeleteUserRole }) {
	if (!users || users.length < 1) {
		return <EmptyPanel>Ingen bedriftsroller er lagt til</EmptyPanel>;
	}

	return (
		<List
			sx={{
				padding: 0,
				marginBottom: '20px',
			}}
		>
			{users.map((user, index) => {
				return (
					<UserItem
						key={`user-role-item-${index}`}
						option={user}
						handleDeleteUserRole={handleDeleteUserRole}
					/>
				);
			})}
		</List>
	);
}

function UserItem({ option, handleDeleteUserRole }) {
	const { verify } = useConfirmDialog();

	const { value, navigateTo, label, role } = option || {};

	function renderPrimary() {
		if (!navigateTo) {
			return label;
		}

		return <UnderlineLink to={navigateTo}>{label}</UnderlineLink>;
	}

	async function handleDelete() {
		if (
			!(await verify({
				title: 'Fjern bruker fra bedrift',
				text: 'Er du sikker på at du ønsker å fjerne brukeren fra bedriften? (Brukeren blir ikke slettet).',
			}))
		) {
			return;
		}

		handleDeleteUserRole({
			id: value,
			role: role,
		});
	}

	return (
		<>
			<ListItem
				secondaryAction={
					<Tooltip
						title="Fjern bruker fra bedriften"
						placement="left"
					>
						<IconButton
							edge="end"
							aria-label="Slett"
							onClick={handleDelete}
						>
							<Icon
								icon="trash"
								size=""
								className="button-icon"
								solid
							/>
						</IconButton>
					</Tooltip>
				}
			>
				<ListItemText
					primary={renderPrimary()}
					secondary={getRoleLabel(role)}
				/>
			</ListItem>
			<Divider as="li" />
		</>
	);
}
