import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export const types = {
	EXISTING: 'existing',
	NEW: 'new',
};

export default function AddUserTypeToggle({ type, handleTypeChange }) {
	return (
		<ToggleButtonGroup
			color="primary"
			value={type}
			exclusive
			onChange={handleTypeChange}
			aria-label="Velg skjema for ny eller eksisterende bruker"
			sx={{
				display: 'grid',
				gridTemplateColumns: '1fr 1fr',
				padding: '20px 24px 0px',
			}}
		>
			<ToggleButton value={types.EXISTING}>
				Eksisterende bruker
			</ToggleButton>
			<ToggleButton value={types.NEW}>Ny bruker</ToggleButton>
		</ToggleButtonGroup>
	);
}
