import { useNavigate } from 'react-router';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import { InlineLoading } from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';

import CompanyTasks from 'web/components/assignmentTasksCalendarTimeline/CompanyTasks';

import { COMPANY_HAS_TASKS } from 'api/queries/companyAssignmentTaskQueries';

const Header = styled.div`
	display: flex;
	gap: 20px;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 40px;
`;

const Title = styled.h1`
	margin: 0;
`;

export default function AssignmentTasksTab({ company }) {
	const {
		data: { companyHasTasks } = { companyHasTasks: false },
		loading,
		error,
	} = useQuery(COMPANY_HAS_TASKS, {
		variables: { companyId: company._id },
	});

	if (loading) return <InlineLoading />;

	if (error) return <ErrorMessage errors={[error]} />;

	if (!companyHasTasks) {
		return <NoCompanyTasksMessage companyId={company._id} />;
	}

	return <CompanyTasks companyId={company._id} />;
}

function NoCompanyTasksMessage({ companyId }) {
	const navigate = useNavigate();

	return (
		<Header>
			<div>
				<Title>Oppdragsutførelse</Title>

				<p>
					Det er ikke planlagt noen oppgaver for denne bedriften ennå.
				</p>
			</div>

			<Button
				type="button"
				variant="contained"
				startIcon={<AddIcon />}
				onClick={() => navigate(`/bedrifter/${companyId}/oppdrag/ny`)}
			>
				Planlegg oppgaver
			</Button>
		</Header>
	);
}
