import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';

const StyledOption = styled.li`
	span {
		font-style: ${p => p.$subdued && 'italic'};
		color: ${p => p.$subdued && p.theme.palette.status.subdued};
	}
	&:hover span {
		color: ${p => p.$subdued && p.theme.palette.common.black};
	}
`;

const StyledTooltipLabel = styled.span`
	display: block;
	width: 100%;
`;
const StyledTooltipContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

export default function AutocompleteOptionWithTooltip({ option, ...props }) {
	return (
		<StyledOption
			{...props}
			$subdued={option.subdued}
			aria-disabled={option.disabled}
		>
			{option.tooltip ? (
				<OptionTooltip title={option.label} option={option}>
					<span>{option.label}</span>
				</OptionTooltip>
			) : (
				<span>{option.label}</span>
			)}
		</StyledOption>
	);
}

function OptionTooltip({ children, option }) {
	return (
		<Tooltip
			title={
				<StyledTooltipContent>
					{option.tooltip?.map((t, i) => (
						<div key={i}>{t}</div>
					))}
				</StyledTooltipContent>
			}
		>
			<StyledTooltipLabel>{children}</StyledTooltipLabel>
		</Tooltip>
	);
}
