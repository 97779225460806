import styled from 'styled-components';

export const columnWidth = 80;
const totalMonths = 3 * 12; // 3 years
const contentWidth = totalMonths * columnWidth;
const assignmentSidebarWidth = 320;

export const Cell = styled.div`
	background-color: ${p =>
		p.$current
			? p => p.theme.palette.background.lightGrayBlue
			: p.theme.palette.background.default};
	display: flex;
	align-items: center;
	gap: 2px;
	height: 40px;
`;

export const CalendarTimelineWrapper = styled.div`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	width: ${contentWidth + assignmentSidebarWidth}px;
`;

export const TimelineSidebarSpacer = styled.div`
	position: sticky;
	order: 1;
	left: 0;
	top: 0;
	width: ${assignmentSidebarWidth}px;
	height: 80px;
	z-index: 3;
	background-color: ${p => p.theme.palette.background.gray};
`;

export const TimelineAssignmentSidebar = styled.div`
	position: sticky;
	order: 3;
	left: 0;
	background-color: ${p => p.theme.palette.background.paper};
	width: ${assignmentSidebarWidth}px;
	z-index: 2;
`;

export const TaskRow = styled.div`
	background: ${p => p.theme.palette.common.gray};
	border-right: 1px solid ${p => p.theme.palette.common.gray};
	flex-shrink: 0;
	display: grid;
	gap: 1px;
	grid-template-columns: 1fr;
	margin-bottom: 15px;
`;

export const TaskCell = styled(Cell)`
	padding: 10px 20px;
`;

export const CategoryName = styled.div`
	font-size: 14px;
	font-weight: bold;
`;

export const TaskName = styled.div`
	font-size: 14px;
	line-height: 1;
	padding-left: 20px;
`;

export const CalendarHeader = styled.div`
	background-color: ${p => p.theme.palette.common.gray};
	border-bottom: 1px solid ${p => p.theme.palette.common.gray};
	position: sticky;
	order: 2;
	width: ${contentWidth}px;
	top: 0;
	z-index: 1;
`;

export const YearRow = styled.div`
	line-height: 1;
	display: grid;
	grid-template-columns: repeat(36, 80px);
	gap: 1px;
	margin-bottom: 1px;
`;

export const YearCell = styled(Cell)`
	background: ${p => p.theme.palette.background.default};
	font-size: 12px;
	letter-spacing: 1px;
	justify-content: center;
	text-transform: uppercase;
	grid-column: span 12;

	font-weight: ${({ $current }) => ($current ? 'bold' : 'normal')};
`;

export const MonthRow = styled.div`
	line-height: 1;
	display: grid;
	grid-template-columns: repeat(36, 80px);
	gap: 1px;
`;

export const MonthCell = styled(Cell)`
	font-size: 12px;
	letter-spacing: 1px;
	justify-content: center;
	text-transform: uppercase;

	font-weight: ${({ $current }) => ($current ? 'bold' : 'normal')};
`;

export const TimelineContentWrapper = styled.div`
	background-color: ${p => p.theme.palette.common.gray};
	border: 1px solid ${p => p.theme.palette.common.gray};
	border-left: none;
	border-top: none;
	order: 4;
	position: relative;
	width: ${contentWidth}px;
`;

export const TimelineGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(36, 80px);
	gap: 1px;
`;

export const GroupCell = styled(Cell)`
	grid-column: 1 / -1;
	background: ${p => p.theme.palette.background.gray};
`;
