import CompanyName from '../cells/CompanyName';
import TaskDeadlineEntryStatus from '../cells/TaskDeadlineEntryStatus';
import TaskDeadlineEntryStatusByType from '../cells/TaskDeadlineEntryStatusByType';
import QualityControlControlStatus from '../cells/QualityControlControlStatus';
import AnnualSettlementControlStatus from '../cells/AnnualSettlementControlStatus';
import RiskScore from '../cells/RiskScore';
import DeviationStatus from '../cells/DeviationStatus';

const defaultColumns = [
	{
		field: 'name',
		renderHeader: () => <strong>Navn</strong>,
		width: 300,
		renderCell: ({ id, row }) => <CompanyName id={id} company={row} />,
		sortable: true,
		hideable: false,
	},
	{
		field: 'skattemelding',
		headerName: 'Skattemelding',
		renderCell: ({ id, row: { setEditAssignmentEntry } }) => (
			<TaskDeadlineEntryStatusByType
				companyId={id}
				type="TAX_NOTICE"
				setEditAssignmentEntry={setEditAssignmentEntry}
			/>
		),
		sortable: false,
		width: 230,
	},
	{
		field: 'arsregnskap',
		headerName: 'Årsregnskap',
		renderCell: ({ id, row: { setEditAssignmentEntry } }) => (
			<TaskDeadlineEntryStatusByType
				companyId={id}
				type="ANNUAL_ACCOUNTS"
				setEditAssignmentEntry={setEditAssignmentEntry}
			/>
		),

		sortable: false,
		width: 230,
	},
	{
		field: 'kvalitetskontroll',
		headerName: 'Kvalitetskontroll',
		renderCell: ({ id }) => <QualityControlControlStatus companyId={id} />,
		sortable: false,
		width: 130,
	},
	{
		field: 'arsoppgjorkontroll',
		headerName: 'Årsoppgjørkontroll',
		renderCell: ({
			id,
			row: { selfRevisionRequired, reviewedAnnualFinancialStatus },
		}) => (
			<AnnualSettlementControlStatus
				companyId={id}
				selfRevisionRequired={selfRevisionRequired}
				reviewedAnnualFinancialStatus={reviewedAnnualFinancialStatus}
			/>
		),
		sortable: false,
		width: 145,
	},
	{
		field: 'hvitvasking',
		headerName: 'Hvitvasking',
		renderCell: ({ id, row: { accountTypes } }) => (
			<RiskScore companyId={id} accountTypes={accountTypes} />
		),
		sortable: false,
		width: 140,
	},
	{
		field: 'avvik',
		headerName: 'Avvik',
		renderCell: ({ id, row: { dataDeviations } }) => (
			<DeviationStatus
				id={id}
				hasDeviations={dataDeviations?.hasDeviations}
			/>
		),
		sortable: false,
		width: 60,
		align: 'center',
	},
];

const getColumns = tasks => {
	let columns = [...defaultColumns];

	tasks.forEach(task => {
		columns.push({
			field: task,
			headerName: task,
			renderCell: ({ id, row: { setEditAssignmentEntry } }) => (
				<TaskDeadlineEntryStatus
					companyId={id}
					task={task}
					setEditAssignmentEntry={setEditAssignmentEntry}
				/>
			),
			sortable: false,
			minWidth: 165,
		});
	});

	return columns;
};

export default getColumns;
