import { useEffect } from 'react';
import { useQuery } from '@apollo/client';

import TasksLoading from './TasksLoading';

import { useCalendarTimeline } from 'web/components/assignmentTasksCalendarTimeline/context/CalendarTimelineContext';
import { months } from 'web/components/assignmentTasksCalendarTimeline/utils';

import { GET_TASK_DEADLINE_ENTRIES } from 'api/queries/companyAssignmentTaskQueries';
import TaskMonthlyDeadlines from './TaskMonthlyDeadlines';

export default function TaskDeadlines({ taskId }) {
	const { showOnlyOverdue, years, setTasksDealineDates } =
		useCalendarTimeline();

	const {
		data: { deadlineEntries } = { deadlineEntries: [] },
		loading,
		error,
	} = useQuery(GET_TASK_DEADLINE_ENTRIES, {
		variables: {
			taskId,
			isOverdue: showOnlyOverdue,
		},
	});

	if (error) {
		console.error(error);
	}

	useEffect(() => {
		// If showOnlyOverdue is true, set the deadline dates
		// The context will use this to position scroll to the first overdue task
		if (showOnlyOverdue) {
			setTasksDealineDates(dates => {
				const deadlineDates = deadlineEntries.map(
					deadlineEntry => deadlineEntry.date
				);

				// Create a Set to store unique dates
				const uniqueDates = new Set(dates);

				// Add only unique dates from deadlineDates
				deadlineDates.forEach(date => {
					uniqueDates.add(date);
				});

				// Convert the Set back to an array
				const uniqueDatesArray = Array.from(uniqueDates);

				return uniqueDatesArray;
			});
		}
	}, [deadlineEntries, setTasksDealineDates, showOnlyOverdue]);

	if (loading) {
		return <TasksLoading />;
	}

	return (
		<>
			{years.map(year =>
				months.map((month, monthIndex) => (
					<TaskMonthlyDeadlines
						deadlineEntries={deadlineEntries}
						year={year}
						month={month}
						monthIndex={monthIndex}
						key={monthIndex}
					/>
				))
			)}
		</>
	);
}
