import { useCalendarTimeline } from 'web/components/assignmentTasksCalendarTimeline/context/CalendarTimelineContext';
import { months } from 'web/components/assignmentTasksCalendarTimeline/utils';
import { Cell } from 'web/components/assignmentTasksCalendarTimeline/timeline/Layout';

export default function TasksLoading() {
	const { years } = useCalendarTimeline();

	return (
		<>
			{years.map(year =>
				months.map(month => (
					<Cell key={`${year}-${month.short}`}></Cell>
				))
			)}
		</>
	);
}
