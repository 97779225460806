import { useState, useCallback } from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Collapse from '@mui/material/Collapse';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';

import { calculationFunctions } from 'server/templates/assignmentRisks';

import { formatDate } from 'utils/dates';
import { sanitizeHtml } from 'utils/sanitizers';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import Message from 'components/messages/Message';
import Revision from 'components/Revision';
import ErrorMessage from 'components/ErrorMessage';
import DeleteButton from 'components/buttons/DeleteButton';

import DesktopLayout, {
	MediumContent,
	StickyAction,
} from 'web/components/Layout';
import HelpText from 'web/components/HelpText';

import {
	FIND_ONE_ASSIGNMENT_RISK,
	GET_ASSIGNMENT_RISKS_HISTORY,
} from 'api/queries/assignmentRiskQueries';
import {
	UPDATE_ONE_ASSIGNMENT_RISK,
	UPDATE_ONE_ASSIGNMENT_RISK_QUESTION_VALUE,
	DELETE_ONE_ASSIGNMENT_RISK,
} from 'api/mutations/assignmentRiskMutations';

const Title = styled.h1`
	margin: 0 0 30px 0;
`;

const Description = styled.div`
	margin: 30px 0;
`;

const Section = styled.div`
	margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
	margin: 0 0 10px 0;
`;

const SectionContent = styled.div`
	background-color: ${p => p.theme.palette.background.default};
	border: 1px solid ${p => p.theme.palette.background.paper};

	padding: 20px 40px;
`;

export default function AssignmentRiskEdit() {
	const { companyId, riskId } = useParams();
	const navigate = useNavigate();
	const { notify } = useSnackbar();

	const {
		data: { risk } = { risk: null },
		loading: riskLoading,
		error: riskError,
	} = useQuery(FIND_ONE_ASSIGNMENT_RISK, {
		variables: {
			_id: riskId,
		},
	});

	const [updateRisk, { loading: updateRiskLoading, error: updateRiskError }] =
		useMutation(UPDATE_ONE_ASSIGNMENT_RISK, {
			refetchQueries: ['getAssignmentRisks'],
		});

	const [deleteRisk, { loading: deleteRiskLoading, error: deleteRiskError }] =
		useMutation(DELETE_ONE_ASSIGNMENT_RISK, {
			refetchQueries: ['getAssignmentRisks'],
		});

	return (
		<DesktopLayout
			title={`Rediger oppdragsrisiko ${risk?.name}`}
			loading={riskLoading}
			breadcrumbs={[
				{
					to: '/bedrifter/',
					label: 'Bedrifter',
				},
				{
					to: `/bedrifter/${companyId}/rediger/`,
					label: risk?.company?.name || '…',
				},
				{
					to: `/bedrifter/${companyId}/rediger/?tab=oppdrag`,
					label: 'Oppdragsrisiko',
				},
				{
					to: `/bedrifter/${companyId}/oppdragsrisiko/${riskId}/`,
					label: risk?.name || '…',
				},
				{
					label: 'Revider',
				},
			]}
		>
			{risk && (
				<MediumContent>
					<Title>{risk?.name}</Title>

					<ErrorMessage
						errors={[riskError, updateRiskError, deleteRiskError]}
					/>

					{risk.hasChanges && (
						<Message
							type="warning"
							title="NB! Skjemaet har endringer"
						>
							Skjemaet inneholder endringer som ikke er revidert!
						</Message>
					)}

					<Revision
						needsRevision={risk.needsRevision}
						date={risk?.revision?.date}
						by={risk?.revision?.byDisplayName}
					/>

					<AssignmentRisksHistory riskId={risk._id} />

					{risk?.description && (
						<Description
							dangerouslySetInnerHTML={{
								__html: sanitizeHtml(risk.description),
							}}
						/>
					)}

					{risk?.categories?.map(category => (
						<Section key={category.title}>
							<SectionTitle>{category.title}</SectionTitle>

							<SectionContent>
								<Questions
									assignmentRiskId={risk._id}
									questions={category.questions}
								/>
							</SectionContent>
						</Section>
					))}

					<StickyAction>
						<DeleteButton
							disabled={deleteRiskLoading}
							onClick={async () => {
								const { data } = await deleteRisk({
									variables: {
										_id: risk._id,
									},
								});

								if (!data.deleted) {
									throw new Error(
										'Det oppstod en feil ved sletting!'
									);
								}
							}}
							redirect={`/bedrifter/${companyId}/rediger/?tab=oppdrag`}
						/>

						<Button
							variant="contained"
							size="large"
							disabled={updateRiskLoading}
							startIcon={<SaveIcon />}
							loadingPosition="start"
							loading={updateRiskLoading}
							onClick={async () => {
								try {
									const { data } = await updateRisk({
										variables: { _id: risk._id },
									});

									if (data?.updatedRisk?._id) {
										notify('Risikoen ble revidert!');

										navigate(
											`/bedrifter/${companyId}/rediger/?tab=oppdrag`
										);
									}
								} catch (err) {
									console.error(err);
								}
							}}
						>
							Revider
						</Button>
					</StickyAction>
				</MediumContent>
			)}
		</DesktopLayout>
	);
}

function Questions({ assignmentRiskId, questions }) {
	const [updateQuestionValue, { loading, error }] = useMutation(
		UPDATE_ONE_ASSIGNMENT_RISK_QUESTION_VALUE,
		{
			refetchQueries: ['findOneAssignmentRisk'],
		}
	);
	const [questionValueSaved, setQuestionValueSaved] = useState(false);

	const handleUpdateQuestionValue = async (questionId, value) => {
		try {
			await updateQuestionValue({
				variables: {
					assignmentRiskId,
					questionId,
					value: String(value),
				},
			});

			setQuestionValueSaved(true);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<div>
			<ErrorMessage errors={error} />

			<QuestionValueUpdatedSnackbar
				open={questionValueSaved}
				handleClose={() => setQuestionValueSaved(false)}
			/>

			{questions.map(question => {
				if (question.dependsOn) {
					try {
						const dependsOnQuestion = questions.find(
							q => q.id === question.dependsOn
						);

						if (!dependsOnQuestion) {
							throw new Error(
								`Depens on question with id ${question.dependsOn} not found`
							);
						}

						if (
							dependsOnQuestion.value !== question.dependsOnValue
						) {
							return null;
						}
					} catch (err) {
						console.error(err);

						return null;
					}
				}

				return (
					<QuestionField
						key={question.id}
						allQuestions={questions}
						question={question}
						handleUpdate={handleUpdateQuestionValue}
						loading={loading}
					/>
				);
			})}
		</div>
	);
}

const QuestionWrapper = styled.div`
	margin-bottom: 20px;
`;

const QuestionTitleWrapper = styled.div`
	margin: 0 0 10px 0;
	position: relative;
	display: flex;
	align-items: center;
	gap: 5px;
`;

const QuestionTitle = styled.h4`
	margin: 0;
`;

const QuestionFieldTypes = {
	title: TitleQuestionField,
	html: HtmlQuestionField,
	boolean: BooleanQuestionField,
	describe: PositiveDescribeQuestionField,
	negativeDescribe: NegativeDescribeQuestionField,
	text: TextQuestionField,
	textarea: TextAreaQuestionField,
	number: NumberQuestionField,
	currency: CurrencyQuestionField,
	choice: ChoiceQuestionField,
	suggestion: SuggestionQuestionField,
	risk: RiskQuestionField,
	calculation: CalculationQuestionField,
};

function QuestionField({ allQuestions, question, handleUpdate }) {
	const QuestionComponent =
		QuestionFieldTypes[question.type] || NotImplementedQuestionField;

	return (
		<QuestionComponent
			allQuestions={allQuestions}
			question={question}
			handleUpdate={handleUpdate}
		/>
	);
}

function Question({
	title = null,
	helpText = null,
	description = null,
	children,
}) {
	return (
		<QuestionWrapper>
			{title && (
				<QuestionTitleWrapper>
					<QuestionTitle>{title}</QuestionTitle>

					{helpText && <HelpText text={helpText} />}
				</QuestionTitleWrapper>
			)}

			{description && (
				<RiskQuestionDescription>
					<div
						dangerouslySetInnerHTML={{
							__html: sanitizeHtml(description),
						}}
					/>
				</RiskQuestionDescription>
			)}

			<div>{children}</div>
		</QuestionWrapper>
	);
}

const NotImplementedMessage = styled.div`
	border: 1px solid ${p => p.theme.palette.error.main};
	color: ${p => p.theme.palette.error.main};
	padding: 20px;
`;

function NotImplementedQuestionField({ question }) {
	return (
		<NotImplementedMessage>
			Spørsmålstype «{question.type}» er ikke implementert!
		</NotImplementedMessage>
	);
}

function TitleQuestionField({ question }) {
	return (
		<Question
			title={question.title}
			helpText={question.helpText}
			description={question.description}
		/>
	);
}

function HtmlQuestionField({ question }) {
	return (
		<Question>
			<div
				dangerouslySetInnerHTML={{
					__html: sanitizeHtml(question.value),
				}}
			/>
		</Question>
	);
}

function BooleanQuestionField({ question, handleUpdate }) {
	return (
		<Question
			title={question.title}
			helpText={question.helpText}
			description={question.description}
		>
			<ButtonGroup>
				<Button
					variant={question.value ? 'contained' : 'outlined'}
					onClick={() => handleUpdate(question.id, 'Ja')}
				>
					Ja
				</Button>

				<Button
					variant={!question.value ? 'contained' : 'outlined'}
					onClick={() => handleUpdate(question.id, '')}
				>
					Nei
				</Button>
			</ButtonGroup>
		</Question>
	);
}

function PositiveDescribeQuestionField({ question, handleUpdate }) {
	const activeYesButton = question.value && question.value !== 'Nei';
	const activeNoButton = question.value && question.value === 'Nei';

	// We want to higlight the 'Ja' button and show the text field if the user has selected "Ja" or entered a value.
	// The value entered in the textfield should override the default 'Ja' or 'Nei' values set by the buttons.

	return (
		<DescribeQuestionField
			question={question}
			handleUpdate={handleUpdate}
			activeYesButton={activeYesButton}
			activeNoButton={activeNoButton}
			showDescribeField={activeYesButton}
		/>
	);
}

function NegativeDescribeQuestionField({ question, handleUpdate }) {
	const activeYesButton = question.value && question.value === 'Ja';
	const activeNoButton = question.value && question.value !== 'Ja';

	// We want to higlight the 'Nei' button and show the text field if the user has selected "Nei" or entered a value.
	// The value entered in the textfield should override the default 'Ja' or 'Nei' values set by the buttons.

	return (
		<DescribeQuestionField
			question={question}
			handleUpdate={handleUpdate}
			activeYesButton={activeYesButton}
			activeNoButton={activeNoButton}
			showDescribeField={activeNoButton}
		/>
	);
}

const DescribeQuestionFieldGroup = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

function DescribeQuestionField({
	question,
	handleUpdate,
	activeYesButton,
	activeNoButton,
	showDescribeField,
}) {
	return (
		<Question
			title={question.title}
			helpText={question.helpText}
			description={question.description}
		>
			<DescribeQuestionFieldGroup>
				<ButtonGroup>
					<Button
						variant={activeYesButton ? 'contained' : 'outlined'}
						onClick={() => handleUpdate(question.id, 'Ja')}
					>
						Ja
					</Button>

					<Button
						variant={activeNoButton ? 'contained' : 'outlined'}
						onClick={() => handleUpdate(question.id, 'Nei')}
					>
						Nei
					</Button>
				</ButtonGroup>

				{showDescribeField && (
					<TextFieldWithServerUpdate
						type="text"
						name={question.id}
						label={question.title}
						value={question.value
							.replace(/\b(Ja|Nei)\b/g, '')
							.trim()}
						onChange={handleUpdate}
						fullWidth
					/>
				)}
			</DescribeQuestionFieldGroup>
		</Question>
	);
}

function TextQuestionField({ question, handleUpdate }) {
	return (
		<Question
			title={question.title}
			helpText={question.helpText}
			description={question.description}
		>
			<TextFieldWithServerUpdate
				type="text"
				name={question.id}
				value={question.value}
				onChange={handleUpdate}
				fullWidth
			/>
		</Question>
	);
}

function TextAreaQuestionField({ question, handleUpdate }) {
	return (
		<Question
			title={question.title}
			helpText={question.helpText}
			description={question.description}
		>
			<TextFieldWithServerUpdate
				multiline
				rows={4}
				name={question.id}
				value={question.value}
				onChange={handleUpdate}
				fullWidth
			/>
		</Question>
	);
}

function NumberQuestionField({ question, handleUpdate }) {
	return (
		<Question
			title={question.title}
			helpText={question.helpText}
			description={question.description}
		>
			<TextFieldWithServerUpdate
				type="number"
				name={question.id}
				value={question.value}
				onChange={handleUpdate}
			/>
		</Question>
	);
}

const FieldWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

function CurrencyQuestionField({ question, handleUpdate }) {
	return (
		<Question
			title={question.title}
			helpText={question.helpText}
			description={question.description}
		>
			<FieldWrapper>
				<TextFieldWithServerUpdate
					type="number"
					name={question.id}
					value={question.value}
					onChange={handleUpdate}
				/>

				<div>kr</div>
			</FieldWrapper>
		</Question>
	);
}

function TextFieldWithServerUpdate(props) {
	const [textFieldValue, setTextFieldValue] = useState(props.value || '');

	// Only save to the server when the user has stopped typing for 750ms
	const handleUpdateToServer = useCallback(
		debounce(value => {
			props.onChange(props.name, value);
		}, 750),
		[]
	);

	return (
		<TextField
			{...props}
			value={textFieldValue || ''}
			onChange={event => {
				setTextFieldValue(event.target.value);
				handleUpdateToServer(event.target.value);
			}}
		/>
	);
}

function ChoiceQuestionField({ question, handleUpdate }) {
	const [selected, setSelected] = useState(question.value || '');

	// Only save to the server when the user has stopped typing for 750ms
	const handleUpdateToServer = useCallback(
		debounce(value => {
			handleUpdate(question.id, value);
		}, 750),
		[]
	);

	if (question.options.length === 2) {
		return (
			<Question
				title={question.title}
				helpText={question.helpText}
				description={question.description}
			>
				<ButtonGroup>
					{question.options.map(option => (
						<Button
							key={option}
							variant={
								question.value === option
									? 'contained'
									: 'outlined'
							}
							onClick={() => {
								setSelected(option);
								handleUpdateToServer(option);
							}}
						>
							{option}
						</Button>
					))}
				</ButtonGroup>
			</Question>
		);
	}

	return (
		<Question
			title={question.title}
			helpText={question.helpText}
			description={question.description}
		>
			<FormControl sx={{ minWidth: '50%' }}>
				<InputLabel id="choice-label">{question.title}</InputLabel>

				<Select
					labelId="choice-label"
					id="choice-select"
					value={selected}
					label={question.title}
					onChange={event => {
						setSelected(event.target.value);
						handleUpdateToServer(event.target.value);
					}}
				>
					{question.options.map(option => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Question>
	);
}

function SuggestionQuestionField({ question, handleUpdate }) {
	const [selected, setSelected] = useState(question.value || '');

	// Only save to the server when the user has stopped typing for 750ms
	const handleUpdateToServer = useCallback(
		debounce(value => {
			handleUpdate(question.id, value);
		}, 750),
		[]
	);

	return (
		<Question
			title={question.title}
			helpText={question.helpText}
			description={question.description}
		>
			<Autocomplete
				value={selected}
				options={question.options}
				onChange={(_event, newValue) => {
					setSelected(newValue);
					handleUpdateToServer(newValue);
				}}
				renderInput={params => <TextField {...params} />}
				autoSelect
				freeSolo
			/>
		</Question>
	);
}

const RiskQuestionFieldWrapper = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	position: relative;
`;

const RiskQuestionTitle = styled(QuestionTitle)`
	opacity: ${p => (p.$subdued ? 0.6 : 1)};
	flex: 1;
`;

const RiskQuestionDescription = styled.div`
	margin-bottom: 20px;
`;

const RiskQuestionButtonGroup = styled(ButtonGroup)`
	margin-bottom: 5px;
`;

const riskChoices = ['Høy', 'Middels', 'Lav', 'IA'];

const riskColors = {
	Høy: 'error',
	Middels: 'warning',
	Lav: 'success',
	IA: 'info',
};

function RiskQuestionField({ question, handleUpdate }) {
	return (
		<RiskQuestionFieldWrapper>
			<RiskQuestionTitle>{question.title}</RiskQuestionTitle>

			{question.helpText && (
				<HelpText text={question.helpText} absolutePosition />
			)}

			<RiskQuestionButtonGroup>
				{riskChoices.map(choice => (
					<Button
						key={choice}
						variant={
							question.value === choice ? 'contained' : 'outlined'
						}
						color={riskColors[choice]}
						onClick={() => handleUpdate(question.id, choice)}
						fullWidth
					>
						{choice}
					</Button>
				))}
			</RiskQuestionButtonGroup>
		</RiskQuestionFieldWrapper>
	);
}

function CalculationQuestionField({ allQuestions, question }) {
	const calculationFunction = calculationFunctions[question.id];

	if (!calculationFunction) {
		return (
			<NotImplementedMessage>
				Missing calculation function for question
			</NotImplementedMessage>
		);
	}

	const calculatedValue = calculationFunction(allQuestions);

	return (
		<Question title={question.title} helpText={question.helpText}>
			{calculatedValue}
		</Question>
	);
}

const SlideTransition = props => <Slide {...props} direction="left" />;

function QuestionValueUpdatedSnackbar({ open, handleClose }) {
	return (
		<Snackbar
			open={open}
			autoHideDuration={4000}
			onClose={handleClose}
			TransitionComponent={SlideTransition}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
		>
			<Alert
				onClose={handleClose}
				severity="success"
				variant="filled"
				sx={{ width: '100%' }}
			>
				Endringen ble lagret
			</Alert>
		</Snackbar>
	);
}

const AssignmentRisksHistoryWrapper = styled.div`
	margin-bottom: 20px;

	@media print {
		display: none;
	}
`;

const AssignmentRisksHistoryActions = styled.div`
	display: flex;
	gap: 10px;
	justify-content: flex-end;
	margin-bottom: 20px;
`;

function AssignmentRisksHistory({ riskId }) {
	const [showHistory, setShowHistory] = useState(false);

	return (
		<AssignmentRisksHistoryWrapper>
			<AssignmentRisksHistoryActions>
				<Button
					onClick={() => setShowHistory(prevHistory => !prevHistory)}
				>
					Revisjonshistorikk
				</Button>
			</AssignmentRisksHistoryActions>

			<Collapse in={showHistory}>
				<AssignmentRisksHistoryList riskId={riskId} />
			</Collapse>
		</AssignmentRisksHistoryWrapper>
	);
}

function AssignmentRisksHistoryList({ riskId }) {
	const {
		data: { risks } = { risks: [] },
		loading,
		error,
	} = useQuery(GET_ASSIGNMENT_RISKS_HISTORY, {
		variables: {
			_id: riskId,
		},
	});

	return (
		<Section>
			<SectionContent>
				<ErrorMessage errors={[error]} />

				{loading && <div>Laster...</div>}

				{risks.length === 0 ? (
					<div>Skjemaet har ingen revisjoner.</div>
				) : (
					<ul>
						{risks.map(risk => (
							<li key={risk._id}>
								Revidert{' '}
								<i>
									{formatDate(
										risk.revision.date,
										'DD.MM.YY [kl] HH:mm'
									)}
								</i>{' '}
								av <i>{risk.revision.byDisplayName}</i>
							</li>
						))}
					</ul>
				)}
			</SectionContent>
		</Section>
	);
}
