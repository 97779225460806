import ScrollContainer from './parts/ScrollContainer';

import { CalendarTimelineWrapper, TimelineSidebarSpacer } from './Layout';

import TimelineSidebar from './parts/TimelineSidebar';
import TimelineHeader from './parts/TimelineHeader';
import TimelineContent from './parts/TimelineContent';

import { useCalendarTimeline } from '../context/CalendarTimelineContext';

export default function CalendarTimeline() {
	const { scrollPosition } = useCalendarTimeline();

	return (
		<ScrollContainer jumpToPosition={scrollPosition}>
			<CalendarTimelineWrapper>
				<TimelineSidebarSpacer />

				<TimelineSidebar />

				<TimelineHeader />

				<TimelineContent />
			</CalendarTimelineWrapper>
		</ScrollContainer>
	);
}
