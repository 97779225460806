import { useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import Grid from '@mui/material/Grid2';

import {
	DocumentTitleWrapper,
	DocumentTitle,
	DocumentPath,
} from 'components/DocumentLayout';

import Card from 'components/Card';
import Icon from 'components/Icon';
import DocumentIcon from 'components/DocumentIcon';

import Signers from 'web/components/Signers';

import {
	CLEAR_RECENT_DOCUMENTS,
	REQUEST_SIGNED_DOCUMENT_DOWNLOAD_URL,
	REQUEST_DOCUMENT_DOWNLOAD_URL,
} from 'api/mutations/documentMutations';
import CancelSigning from './CancelSigning';
import DownloadDocument from './DownloadDocument';
import ActionLinks from './ActionLinks';
import ViewPdf from './ViewPdf';

const ManuallySigned = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	margin-bottom: 56px;
	line-height: 1;

	i {
		color: ${p => p.theme.palette.status.success};
	}
`;

export default function Document({ documentNode }) {
	const {
		_id: documentId,
		name,
		path,
		type,
		isHidden,
		isSigning,
		isSigningComplete,
		signedPDFGenerated,
		manuallySigned,
	} = documentNode;
	const isPdf = documentNode?.contentType?.includes('pdf');

	const [getSignedDocument] = useMutation(
		REQUEST_SIGNED_DOCUMENT_DOWNLOAD_URL,
		{
			variables: {
				documentId,
			},
		}
	);

	const [getDocument] = useMutation(REQUEST_DOCUMENT_DOWNLOAD_URL, {
		variables: {
			documentId,
		},
	});

	const [markAsViewed] = useMutation(CLEAR_RECENT_DOCUMENTS);

	useEffect(() => {
		if (!documentNode || type === 'folder') return;

		markAsViewed({ variables: { ids: [documentId] } });
	}, [documentId]);

	if (type !== 'file') return null;

	return (
		<Card
			icon={
				<DocumentIcon
					needsSignature={isSigning}
					hasSignature={isSigningComplete}
					size="2xl"
				/>
			}
		>
			<DocumentTitleWrapper>
				<DocumentTitle>{name}</DocumentTitle>

				<DocumentPath>{path}</DocumentPath>
			</DocumentTitleWrapper>

			{manuallySigned && (
				<ManuallySigned>
					<Icon icon="file-check" />
					Dokumentet er signert manuelt
				</ManuallySigned>
			)}

			{!isHidden && (
				<Grid
					container
					spacing={2}
					sx={{
						mb: 6,
						justifyContent: 'center',
					}}
				>
					<ActionLinks
						documentNode={documentNode}
						sendToSigning={!isSigning && isPdf}
					/>
				</Grid>
			)}

			<Grid
				container
				spacing={2}
				sx={{
					mb: 6,
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<DownloadDocument
					getDocument={getDocument}
					getSignedDocument={getSignedDocument}
					signedPDFGenerated={signedPDFGenerated}
				/>

				{isPdf && (
					<ViewPdf
						documentQuery={getDocument}
						getDocument={getDocument}
						getSignedDocument={getSignedDocument}
						signedPDFGenerated={signedPDFGenerated}
					/>
				)}
			</Grid>

			{!manuallySigned && isSigning && (
				<Grid container spacing={2}>
					<Grid size={12}>
						<Signers
							documentId={documentId}
							title="Mottakere av signering"
						/>
					</Grid>
					{!isSigningComplete && (
						<Grid size={12} sx={{ textAlign: 'center' }}>
							<CancelSigning documentId={documentId} />
						</Grid>
					)}
				</Grid>
			)}
		</Card>
	);
}
