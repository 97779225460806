import { Controller, useFormContext } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

// Object to handle getting and setting values for the AutoComplete component
const valueHandler = {
	// Method to get the value based on whether multiple selection is enabled
	get(value, options, multiple) {
		if (!multiple) {
			// For single selection, find the option with the matching value
			return options.find(option => option.value === value) ?? null;
		}

		if (!Array.isArray(value)) {
			// For multiple selection, if value is not an array, return an array with the matched option or null
			return value
				? [options.find(option => option.value === value)]
				: null;
		}

		// For multiple selection, map the value array to find the corresponding options
		return value.map(item => options.find(option => option.value === item));
	},

	// Method to set the value based on whether multiple selection is enabled
	set(value, multiple) {
		if (!multiple) {
			// For single selection, return the value's value or null
			return value?.value ?? null;
		}

		if (!Array.isArray(value)) {
			// For multiple selection, if value is not an array, return an array with the value's value or null
			return value ? [value.value] : null;
		}

		// For multiple selection, map the value array to extract the value properties
		return value.map(item => item.value);
	},
};

export default function AutoComplete({
	name,
	label,
	defaultValue = null,
	options = [],
	loading = false,
	required = false,
	multiple = false,
	rules = {},
	...rest
}) {
	const { control } = useFormContext();

	const getValue = value => valueHandler.get(value, options, multiple);
	const setValue = value => valueHandler.set(value, multiple);

	const requiredInRules = required
		? { required: 'Dette feltet er påkrevd' }
		: {};

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={!defaultValue && multiple ? [] : defaultValue}
			rules={{ ...requiredInRules, ...rules }}
			render={({
				field: { onChange, value, ...fieldProps },
				fieldState: { error },
			}) => (
				<Autocomplete
					{...fieldProps}
					value={getValue(value)}
					loading={loading}
					options={options}
					onChange={(_event, item) => onChange(setValue(item))}
					renderInput={params => {
						return (
							<TextField
								label={label}
								required={
									multiple ? value.length === 0 : required
								}
								slotProps={{
									input: {
										...params.InputProps,
										endAdornment: (
											<>
												{loading ? (
													<CircularProgress
														color="inherit"
														size={20}
													/>
												) : null}
												{params.InputProps.endAdornment}
											</>
										),
									},
									htmlInput: {
										...params.inputProps,
									},
								}}
								error={!!error}
								helperText={error?.message ?? ''}
								{...params}
							/>
						);
					}}
					noOptionsText="Ingen alternativer"
					multiple={multiple}
					{...rest}
				/>
			)}
		/>
	);
}
