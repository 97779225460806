import { useEffect, useMemo } from 'react';

import {
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { getCompaniesArchiveTemplates } from 'utils/visibilityTemplates';
import useLocalStorage from 'hooks/useLocalStorageSetting';

export default function GridToolbar({
	setSelectedTemplate,
	selectedTemplate,
	columns = [],
}) {
	const { value: customTemplate } = useLocalStorage(
		'companiesArchiveCustomTemplate',
		null
	);

	const companiesArchiveTemplates = useMemo(
		() => getCompaniesArchiveTemplates(columns),
		[columns]
	);

	// Combine the templates with the custom template
	const templates = useMemo(
		() => [...companiesArchiveTemplates, customTemplate].filter(Boolean),
		[companiesArchiveTemplates, customTemplate]
	);

	useEffect(() => {
		if (companiesArchiveTemplates.length > 0 && !selectedTemplate) {
			setSelectedTemplate(companiesArchiveTemplates[0]);
		}
	}, [companiesArchiveTemplates]);

	return (
		<>
			<GridToolbarContainer
				sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					marginBottom: 2,
				}}
			>
				<Autocomplete
					options={[...templates]}
					renderInput={params => (
						<TextField
							{...params}
							label="Visningsmodus"
							name="company-table-template"
						/>
					)}
					value={selectedTemplate || templates[0]}
					onChange={(_, newValue) => {
						setSelectedTemplate(newValue);
					}}
					size="small"
					sx={{ width: 200 }}
				/>

				<GridToolbarColumnsButton />
				<GridToolbarDensitySelector />
			</GridToolbarContainer>
		</>
	);
}
